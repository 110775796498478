import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import SongUploadPng from '../../assets/images/songupload.png';
import DocumentUpload from '../../assets/images/documentUpload.png';
import BackLink from '../../components/Layouts/BackLink';
import { MdFileUpload } from 'react-icons/md';
import Loading from '../../sparepages/Loading';
import { useNavigate } from 'react-router-dom';
import SessionExpired from '../../components/SessionExpired';
import SideSuccess from '../../components/SideSuccess';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';

const UploadingMusic = () => {
  const navigate = useNavigate();
  const [permission, setPermission] = useState('view');
  const [tokenExpired, setTokenExpired] = useState(false);

  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [sideSuccess, setSideSuccess] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const [songRecordingName, setSongRecordingName] = useState('The Boys');
  const [songFile, setSongFile] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [documentName, setDocumentName] = useState('');

  const dataFetchRef = useRef(false);

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'upload_music',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // to get the song record name
  const handleSongRecordName = (e) => {
    if (e.target.files[0]) {
      setSongFile((prev) => (prev = e.target.files[0]));
      setSongRecordingName(
        (prev) => (prev = e.target.files[0].name.split('.')[0])
      );
    }
  };

  // post data to the server
  const onFileUpload = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const formData = new FormData();

        console.log(songRecordingName);

        formData.append('name', songRecordingName);
        formData.append('files', documentFile);
        formData.append('music', songFile);
        for (const [key, value] of formData) {
          console.log(`${key}: ${value}\n`);
        }

        const data = await fetchServer('/music/upload_music', 'POST', formData);

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        console.log(data);

        setSideSuccess((prev) => (prev = true));

        setTimeout(() => {
          setSideSuccess((prev) => (prev = false));

          setTimeout(() => {
            // navigate('/productManager');
          }, 0);
        }, 2000);
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prev) => (prev = true));
    }
  };

  // to trigger permission check
  useEffect(() => {
    document.title = 'Uploading Music - Overstood';

    // to prevent running twice in fetch data
    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    checkPermission();

    // eslint-disable-next-line
  }, []);

  return (
    (permission === 'view' || permission === 'full') && (
      <div className="w-full h-full p-5">
        {/* background image */}
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />
        {/* background image */}

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* Success Box */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideSuccess ? 'right-4' : '-right-full'
          }`}
        >
          <SideSuccess name={'Upload Successful!'} />
        </div>
        {/* Success Box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Confirm Box */}
        {confirmBox && (
          <div
            className="absolute top-0 left-0 w-screen h-screen z-40"
            onClick={() => {
              setConfirmBox(false);
            }}
          ></div>
        )}

        {confirmBox && (
          <div className="absolute w-[600px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded-xl bg-white z-50 text-black text-center">
            <p className="my-3 text-xl text-tertiary">
              Are you sure you want to upload this recording!
            </p>

            {(songFile === null || documentFile === null) && (
              <p className="text-xl text-secondaryRed">Warning</p>
            )}

            {songFile === null && (
              <p className="text-secondaryRed text-xs">
                You need to select recording file in the recording box.
              </p>
            )}

            {documentFile === null && (
              <p className="text-secondaryRed text-xs">
                You need to select document file in the document box.
              </p>
            )}

            <div className="w-full flex items-center justify-between mt-10 px-28">
              <button
                onClick={() => setConfirmBox(false)}
                className="px-10 py-3 rounded-lg bg-[rgba(0,0,0,0.2)]"
              >
                Cancel
              </button>

              {songFile === null || documentFile === null ? (
                <button className="px-10 py-3 rounded-lg bg-tertiary text-white cursor-not-allowed">
                  Next
                </button>
              ) : (
                <button
                  onClick={() => {
                    setConfirmBox(false);
                    onFileUpload();
                  }}
                  className="px-10 py-3 rounded-lg bg-tertiary text-white"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        )}
        {/* Confirm Box */}

        <div className="absolute p-10 space-y-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-10 backdrop-blur-lg rounded-xl">
          {/* song title */}
          <div className="space-y-3">
            <p>Song Title</p>
            <div className="w-[800px] bg-secondaryGray text-white px-5 py-2 rounded-lg">
              <p>{songRecordingName}</p>
            </div>
          </div>
          {/* song title */}

          <div className="flex space-x-6">
            {/* song recording */}
            <div className="w-96">
              <p className="mb-3">Song Recordings</p>
              <label
                htmlFor={`${permission === 'full' ? 'songRecord' : ''}`}
                className={`py-5 bg-secondaryColor rounded-xl flex items-center justify-center space-x-4 relative overflow-hidden cursor-pointer`}
              >
                {songFile !== null && (
                  <div className="w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.7)] flex justify-center items-center">
                    <p className="text-xl">Recording File is selected</p>
                  </div>
                )}
                <div>
                  <img src={SongUploadPng} alt="" className="w-16" />
                </div>
                <div className="text-center space-y-2">
                  <p className="font-semibold">Drag and Drop your file here</p>
                  <p className="font-thin">File supported: WAV/mp3</p>
                  <p className="text-xl">Or</p>
                  <div className="py-2 px-10 border-2 rounded-full border-opacity-50 text-xs border-[rgba(255,255,255,0.5)]">
                    Browse files
                  </div>
                </div>
              </label>
              <input
                type="file"
                id="songRecord"
                className="hidden"
                accept="audio/*"
                onChange={(e) => handleSongRecordName(e)}
              />
            </div>
            {/* song recording */}

            {/* document file */}
            <div className="w-96">
              <p className="mb-3">Document</p>
              <label
                htmlFor={`${permission === 'full' ? 'documents' : ''}`}
                className={`py-5 bg-secondaryColor rounded-xl flex items-center justify-center space-x-4 relative overflow-hidden cursor-pointer`}
              >
                {documentFile !== null && (
                  <div className="w-full h-full absolute top-0 left-0 bg-[rgba(0,0,0,0.7)] flex flex-col justify-center items-center">
                    <p className="text-xl">Document File is selected</p>
                    <p className="text-xs">file name : {documentName}</p>
                  </div>
                )}
                <div>
                  <img src={DocumentUpload} alt="" className="w-16" />
                </div>
                <div className="text-center space-y-2">
                  <p className="font-semibold">Drag and Drop your file here</p>
                  <p className="font-thin">File supported: pdf/txt/doc</p>
                  <p className="text-xl">Or</p>
                  <div className="py-2 px-10 border-2 rounded-full border-opacity-50 text-xs border-[rgba(255,255,255,0.5)]">
                    Browse files
                  </div>
                </div>
              </label>
              <input
                type="file"
                id="documents"
                className="hidden"
                accept=".pdf, .txt, .png, .jpeg"
                onChange={(e) => {
                  if (e.target.files[0]) {
                    setDocumentFile(e.target.files[0]);
                    setDocumentName(() => e.target.files[0].name);
                  }
                }}
              />
            </div>
            {/* document file */}
          </div>
        </div>

        <div className="absolute bottom-5 pr-10 flex w-full justify-between items-center">
          <BackLink to={'/'} />

          {permission === 'full' && (
            <button
              onClick={() => setConfirmBox((prev) => (prev = true))}
              className="bg-primaryColor px-5 py-2 rounded-md flex space-x-3 items-center"
            >
              <MdFileUpload />
              <span>Upload</span>
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default UploadingMusic;
