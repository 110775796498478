import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import MusicData from '../../assets/images/musicdata.png';
import { Link } from 'react-router-dom';
import Loading from '../../sparepages/Loading';
import { fetchServer } from '../../shares/helper';
import SessionExpired from '../../components/SessionExpired';
import SideAlert from '../../components/SideAlert';

const PreviewIdentity = () => {
  const [identityDatas, setIdentityDatas] = useState([]);

  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const dataFetchRef = useRef(false);

  const fetchIdentity = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const data = await fetchServer('/team/identity', 'GET');

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.error === '') {
          setIdentityDatas(
            (identityDatas) => (identityDatas = data.result.data)
          );
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prev) => (prev = true));
    }
  };

  useEffect(() => {
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;
    fetchIdentity();

    // eslint-disable-next-line
  }, []);
  return (
    <div className="w-full h-full px-5 py-10">
      <img
        src={BackgroundImageZZ}
        className="absolute top-0 left-0 w-full h-full bg-center -z-10"
        alt=""
      />

      {/* if token expired */}
      {tokenExpired && <SessionExpired />}

      {/* Failure Alert */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideAlert ? 'right-4' : '-right-full'
        }`}
      >
        <SideAlert name="Something went wrong" />
      </div>
      {/* Failure Alert */}

      {/* loading box */}
      {loading && (
        <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
          <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
            <Loading />
          </div>
        </div>
      )}
      {/* loading box */}

      <div className="w-3/4 md:w-2/5 py-16 absolute -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2 bg-white bg-opacity-10 backdrop-blur-lg rounded-xl flex flex-col space-y-6 items-center">
        <Link
          to={'/profile'}
          className="absolute top-5 right-5 bg-secondaryColor text-white px-5 py-2 rounded-md"
        >
          <span>Profile</span>
        </Link>

        <div className="flex flex-col items-center">
          <img src={MusicData} alt="" className="w-28" />

          <div className="space-y-2 w-full text-center mt-2">
            <p className="text-xl font-semibold">{identityDatas[0]?.c1.c1}</p>
            <p className="text-xs">Lwin Ko Zin Latt</p>
          </div>
        </div>

        <div className="w-3/4 space-y-8">
          <div className="flex w-full ">
            <p className="w-56">ISNI</p>
            <p className="w-16">-</p>
            <p>{identityDatas[0]?.c1.c3}</p>
          </div>

          <div className="flex w-full">
            <p className="w-56">CAE/IPI</p>
            <p className="w-16">-</p>
            <p>{identityDatas[0]?.c1.c4}</p>
          </div>

          <div className="flex w-full">
            <p className="w-56">ISRC</p>
            <p className="w-16">-</p>
            <p>{identityDatas[0]?.c1.c5 ? identityDatas[0]?.c1.c5 : 'none'}</p>
          </div>

          <div className="flex w-full">
            <p className="w-56">Type</p>
            <p className="w-16">-</p>
            <p>
              {identityDatas[0]?.c1.c6 === 0
                ? 'band'
                : identityDatas[0]?.c1.c6 === 1
                ? 'duo/trio'
                : identityDatas[0]?.c1.c6 === 2
                ? 'label'
                : 'publish entity'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewIdentity;
