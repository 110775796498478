import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import { Link, useNavigate } from 'react-router-dom';
import { fetchServer } from '../../shares/helper';
import Loading from '../../sparepages/Loading';
import SideSuccess from '../../components/SideSuccess';
import SideAlert from '../../components/SideAlert';
import SessionExpired from '../../components/SessionExpired';

const AddMember = () => {
  let [cataLogId, setCataLogId] = useState('');
  let [cataLogs, setCataLogs] = useState([]);
  let [roleId, setRoleId] = useState('');
  let [roles, setRoles] = useState([]);

  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);
  const [sideSuccess, setSideSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const [permission, setPermission] = useState(false);

  const dataFetchRef = useRef(false);

  const navigate = useNavigate();

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const body = {
          page: 'team_member_list',
          method: 'PATCH',
        };
        const data = await fetchServer('/permitCheck', 'POST', body);

        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 1) {
          setPermission((prev) => (prev = true));
          fetchCatalogs();
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // fetching catalogs from the server
  const fetchCatalogs = async () => {
    try {
      const data = await fetchServer('/cat/userCata', 'GET');
      console.log(data);
      setCataLogs((cataLogs = data.result.data));
      setCataLogId((cataLogId = cataLogs[0].c1._id));
    } catch (error) {
      setSideAlert((prev) => (prev = true));

      setTimeout(() => {
        setSideAlert((prev) => (prev = false));
      }, 2000);
    }
  };

  // fetching roles from the server
  const fetchRoles = async (cataLogId) => {
    try {
      const body = {
        catId: cataLogId,
      };

      const data = await fetchServer('/team/team_member_list', 'PATCH', body);
      if (data) {
        setLoading((prev) => (prev = false));
      }

      // console.log(data);
      setRoles((roles = data.result.data));
      setRoleId((roleId = roles[0]?._id));

      // console.log(roleId);
    } catch (error) {
      setSideAlert((prev) => (prev = true));

      setTimeout(() => {
        setSideAlert((prev) => (prev = false));
      }, 2000);
    }
  };

  // invite member of the email written in the mail box
  const pushMember = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          cataId: cataLogId,
          roleId: roleId,
          email: email,
        };

        const data = await fetchServer('/team/team_member_list', 'PATCH', body);

        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error !== '') {
          if (data.result.error.toLowerCase().includes('already')) {
            showErrorMessage('User already exist');
          } else {
            showErrorMessage('Something went wrong');
          }
        } else {
          setSideSuccess((prev) => (prev = true));

          setTimeout(() => {
            setSideSuccess((prev) => (prev = false));

            navigate('/teamMember');
          }, 2000);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // to show error message
  const showErrorMessage = (message) => {
    setErrorMessage((prev) => (prev = message));
    setSideAlert((prev) => (prev = true));

    setTimeout(() => {
      setSideAlert((prev) => (prev = false));
    }, 2000);
  };

  useEffect(() => {
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;

    checkPermission();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    cataLogId !== '' && fetchRoles(cataLogId);

    // eslint-disable-next-line
  }, [cataLogId]);

  return (
    permission === true && (
      <div className="w-full h-full space-y-5">
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Success Box */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideSuccess ? 'right-4' : '-right-full'
          }`}
        >
          <SideSuccess name={'Successfully uploaded!'} />
        </div>
        {/* Success Box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name={errorMessage} />
        </div>
        {/* Failure Alert */}

        <div className="w-3/4 md:w-2/5 py-8 px-6 absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-white bg-opacity-20 backdrop-blur-lg rounded-xl flex flex-col space-y-4">
          <p className="text-xl font-semibold mb-3 text-white">Add Member</p>

          <div className="w-full flex flex-col items-center space-y-3">
            <div className="flex flex-col space-y-3 w-2/3">
              <label htmlFor="">Catalog</label>
              <select
                className="w-full py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
                defaultValue={cataLogId}
                onChange={(e) => setCataLogId(e.target.value)}
              >
                {cataLogs.map((cataLog) => (
                  <option key={cataLog.c1._id} value={cataLog.c1._id}>
                    {cataLog.c1.c1}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col space-y-3 w-2/3">
              <label htmlFor="">Email</label>
              <input
                type="email"
                placeholder="Email"
                className="w-full py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="flex flex-col space-y-3 w-2/3">
              <label htmlFor="">Role</label>

              <select
                className="w-full py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
                defaultValue={roleId}
                onChange={(e) => setRoleId(e.target.value)}
              >
                {roles?.map((role) => (
                  <option key={role._id} value={role._id}>
                    {role.c1}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="w-full flex justify-between items-center pt-8">
            <Link
              to={'/teamMember/permission'}
              className="px-4 py-2 rounded-md bg-secondaryRed text-white"
            >
              Cancel
            </Link>
            <button
              onClick={() => pushMember()}
              className="px-4 py-2 rounded-md bg-primaryColor text-white"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default AddMember;
