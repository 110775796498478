import React from 'react';
import MovieBg from '../../assets/images/moviebg.png';
import LoginArtist from '../../assets/images/loginartist.png';
import BackLink from '../../components/Layouts/BackLink';
import { useParams } from 'react-router-dom';

const Details = () => {
  const params = useParams();

  return (
    <div className="w-screen h-screen p-10">
      <img
        src={MovieBg}
        className="absolute top-0 left-0 w-full h-full bg-center -z-10"
        alt=""
      />

      <div className="w-full h-full py-10 px-10 bg-white bg-opacity-20 backdrop-blur-lg rounded-xl flex flex-col space-y-8 items-center relative">
        <div className="absolute top-10 left-10">
          <BackLink to={`/movie/${params.id}/view/${params.cataId}`} />
        </div>

        <div className="w-28 h-28 rounded-full overflow-hidden flex justify-center items-center">
          <img src={LoginArtist} alt="" className="w-full object-cover" />
        </div>

        <p className="text-white text-xl font-semibold">Boys with Uke</p>

        <div className="w-full flex justify-evenly text-white">
          <div className="space-y-4 px-10">
            <div className="w-full flex justify-center items-center">
              <div
                className={`w-10 h-10 rounded-full border flex justify-center items-center font-bold text-xl`}
              >
                i
              </div>
            </div>

            <div className="flex w-full">
              <p className="w-56">Primary Artist</p>
              <p className="w-16">-</p>
              <p>primaryArtist</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Primary Titles</p>
              <p className="w-16">-</p>
              <p className="w-36">primaryTitle</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Featured Artists</p>
              <p className="w-16">-</p>
              <p>featuredArtists</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Composers</p>
              <p className="w-16">-</p>
              <p>composers</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Producers</p>
              <p className="w-16">-</p>
              <p>producers</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Year of Release</p>
              <p className="w-16">-</p>
              <p>yearOfRelease</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Duration</p>
              <p className="w-16">-</p>
              <p>duration</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Album Titles</p>
              <p className="w-16">-</p>
              <p className="w-36">albumTitle</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">ISWC</p>
              <p className="w-16">-</p>
              <p>iswc</p>
            </div>
          </div>

          <div className="bg-white h-96 w-0.5 absolute left-1/2 -translate-x-1/2"></div>

          <div className="space-y-4 px-10">
            <div className="w-full flex justify-center items-center">
              <div
                className={`w-10 h-10 rounded-full border flex justify-center items-center font-bold text-xl`}
              >
                c
              </div>
            </div>
            <div className="flex w-full ">
              <p className="w-56">Publisher IPI</p>
              <p className="w-16">-</p>
              <p>publisherIpi</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Publisher Name</p>
              <p className="w-16">-</p>
              <p>publisherName</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Collection Share</p>
              <p className="w-16">-</p>
              <p>collectShare</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Recording Titles</p>
              <p className="w-16">-</p>
              <p>recordingTitle</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Recording Artist</p>
              <p className="w-16">-</p>
              <p>recordingArtist</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Recording ISRC</p>
              <p className="w-16">-</p>
              <p>recordingIsrc</p>
            </div>

            <div className="flex w-full">
              <p className="w-56">Label</p>
              <p className="w-16">-</p>
              <p>label</p>
            </div>
          </div>
        </div>

        <button className="py-2 px-4 rounded-lg bg-secondaryColor text-white absolute bottom-10 right-10">
          Contact to Admin
        </button>
      </div>
    </div>
  );
};

export default Details;
