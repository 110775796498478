import React, { useState } from 'react';
import { BsFillSendFill } from 'react-icons/bs';

const HelpAndSupport = ({ userId }) => {
  //   const [chats, setChats] = useState([]);
  const [message, setMessage] = useState('');

  // const fetchChats = async () => {
  //   try {
  //     const token = localStorage.getItem('token');

  //     const body = {
  //       senderId: userId,
  //       receiverId: receiverId,
  //     };

  //     const data = await axios.post('/chat/getTeamMessage', body, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     setChats((prev) => (prev = data.result));

  //     // console.log(data);
  //   } catch (error) {}
  // };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = async (e) => {
    if (e.key === 'Enter') {
      if (message === '') {
        return;
      }
      try {
        // const token = localStorage.getItem('token');

        // const body = {
        //   senderId: userId,
        //   receiverId: receiverId,
        //   content: message,
        // };

        // const data = await axios.post('/chat/sendMessage', body, {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // });

        // if (data) {
        //   fetchChats();
        // }

        clearMessage();

        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const clearMessage = () => {
    setMessage('');
  };

  // useEffect(() => {
  //   userId && receiverId && fetchChats();
  // }, [userId, receiverId]);

  return (
    <>
      <div className="h-full pt-24 flex flex-col-reverse overflow-y-auto pb-24 no-scrollbar">
        {/* {chats.map((chat) =>
          chat.c2._id === userId ? (
            <YourMessage key={chat._id} content={chat.c4} />
          ) : (
            <OtherMessage key={chat._id} name={chat.c2.c1} content={chat.c4} />
          )
        )} */}
      </div>
      <div className="w-full h-24 py-5 flex justify-center items-center absolute bottom-0 pr-10 bg-darkBlue">
        <div className="w-full h-full px-5 rounded-lg bg-secondaryGray flex items-center justify-between space-x-5">
          <input
            type="text"
            className="w-full bg-secondaryGray focus:outline-none"
            value={message}
            onChange={handleChange}
            onKeyDown={sendMessage}
          />
          <button className="text-2xl">
            <BsFillSendFill />
          </button>
        </div>
      </div>
    </>
  );
};

export default HelpAndSupport;
