import React from 'react';
import MovieBg from '../../assets/images/moviebg.png';
import LoginArtist from '../../assets/images/loginartist.png';
import BackLink from '../../components/Layouts/BackLink';
import { useNavigate, useParams } from 'react-router-dom';

const View = () => {
  const params = useParams();
  const navigate = useNavigate();

  const songs = [];
  for (let index = 0; index < 10; index++) {
    songs.push(
      <tr
        onClick={() => navigateToSongDetails(index + 1)}
        className="py-2 bg-black hover:bg-secondaryColor cursor-pointer bg-opacity-20 text-white"
      >
        <td className="whitespace-nowrap px-6 py-2 rounded-tl-xl rounded-bl-xl">
          <img src={LoginArtist} alt="" className="w-12 h-12 rounded-full" />
        </td>
        <td className="whitespace-nowrap px-6 py-2">T_T_T_T</td>
        <td className="whitespace-nowrap px-6 py-2">10:30 mins</td>
        <td className="whitespace-nowrap px-6 py-2">3 Contributors</td>
        <td className="whitespace-nowrap px-6 py-2">ISWC CODE</td>
        <td className="whitespace-nowrap px-6 py-2">2022</td>
        <td className="whitespace-nowrap px-6 py-2">Album Title</td>
        <td className="whitespace-nowrap px-6 py-2 rounded-tr-xl rounded-br-xl">
          20/12/2022
        </td>
      </tr>
    );
  }

  const navigateToSongDetails = (songId) => {
    navigate(`details/${songId}`);
  };

  return (
    <div className="w-screen h-screen p-10">
      <img
        src={MovieBg}
        className="absolute top-0 left-0 w-full h-full bg-center -z-10"
        alt=""
      />

      <div className="w-full h-full py-10 px-10 bg-white bg-opacity-20 backdrop-blur-lg rounded-xl flex flex-col space-y-8 items-center relative">
        <div className="absolute top-10 left-10">
          <BackLink to={`/movie/${params.id}`} />
        </div>

        <div className="w-28 h-28 rounded-full overflow-hidden flex justify-center items-center">
          <img src={LoginArtist} alt="" className="w-full object-cover" />
        </div>

        <p className="text-white text-xl font-semibold">Boys with Uke</p>

        <div className="w-full h-[400px] 2xl:h-[550px]">
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="h-[400px] 2xl:h-[550px] overflow-y-auto customScrollY pr-3">
                  <table
                    className="min-w-full text-left text-sm font-light rounded-md border-spacing-y-2 border-separate"
                    cellSpacing={12}
                  >
                    <tbody>{songs}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
