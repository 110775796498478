import React, { useState } from 'react';
import TeamChat from './TeamChat';
import ChatMembers from './ChatMembers';
import HelpAndSupport from './HelpAndSupport';

const Chat = ({ userId, teamMembers, owner, cataLogId }) => {
  const [chat, setChat] = useState(0);

  const chatType = (state) => {
    setChat((prev) => (prev = state));
  };

  return (
    <>
      <div className="w-full h-full px-5 overflow-y-auto bg-darkBlue relative">
        <div className="absolute top-0 left-0 w-full flex space-x-5 p-5 border-b bg-darkBlue z-10">
          <p
            className={`text-xl cursor-pointer ${
              chat === 0 && ' text-secondaryColor'
            }`}
            onClick={() => chatType(0)}
          >
            Team
          </p>
          <p
            className={`text-xl cursor-pointer ${
              chat === 1 && ' text-secondaryColor'
            }`}
            onClick={() => chatType(1)}
          >
            Members
          </p>
          <p
            className={`text-xl cursor-pointer ${
              chat === 2 && ' text-secondaryColor'
            }`}
            onClick={() => chatType(2)}
          >
            Help and Support
          </p>
        </div>

        <div className="h-full text-sm overflow-hidden">
          {chat === 0 && (
            <TeamChat
              teamMembers={teamMembers}
              cataLogId={cataLogId}
              userId={userId}
              owner={owner}
            />
          )}
          {chat === 1 && (
            <ChatMembers
              teamMembers={teamMembers}
              owner={owner}
              userId={userId}
            />
          )}
          {chat === 2 && <HelpAndSupport userId={userId} />}
        </div>
      </div>
    </>
  );
};

export default Chat;
