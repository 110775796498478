import React, { useEffect, useRef, useState } from 'react';
import SettingsArchieved from './SettingsArchieved';
import Documents from './Documents';
import Templates from './Templates';
import Loading from '../../sparepages/Loading';
import { fetchServer } from '../../shares/helper';
import { useNavigate } from 'react-router-dom';
import SideAlert from '../../components/SideAlert';
import SessionExpired from '../../components/SessionExpired';

const Settings = () => {
  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [permission, setPermission] = useState('');
  const [sideAlert, setSideAlert] = useState(false);

  const dataFetchRef = useRef(false);

  const navigate = useNavigate();

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const body = {
          page: 'archived',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  useEffect(() => {
    document.title = 'Settings - Overstood';

    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    checkPermission();

    // eslint-disable-next-line
  }, []);

  const [activeNumber, setActiveNumber] = useState(1);

  return (
    (permission === 'view' || permission === 'full') && (
      <div className="w-full px-10 text-white">
        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        <div className="w-full py-5">
          <ul className="flex space-x-10">
            <li
              className={
                activeNumber === 1
                  ? 'underline underline-offset-8 cursor-pointer text-secondaryColor'
                  : 'cursor-pointer hover:text-secondaryColor'
              }
              onClick={() => setActiveNumber(1)}
            >
              Archieved
            </li>
            <li
              className={
                activeNumber === 2
                  ? 'underline underline-offset-8 cursor-pointer text-secondaryColor'
                  : 'cursor-pointer hover:text-secondaryColor'
              }
              onClick={() => setActiveNumber(2)}
            >
              Documents
            </li>
            <li
              className={
                activeNumber === 3
                  ? 'underline underline-offset-8 cursor-pointer text-secondaryColor'
                  : 'cursor-pointer hover:text-secondaryColor'
              }
              onClick={() => setActiveNumber(3)}
            >
              Preset Templates
            </li>
          </ul>
        </div>

        <div className="w-full py-5 h-full overflow-y-auto">
          {activeNumber === 1 && <SettingsArchieved setLoading={setLoading} />}
          {activeNumber === 2 && <Documents setLoading={setLoading} />}
          {activeNumber === 3 && <Templates setLoading={setLoading} />}
        </div>
      </div>
    )
  );
};

export default Settings;
