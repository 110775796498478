import React from 'react';

const OtherMessage = ({ name = 'other', content }) => {
  return (
    <div className="w-full flex justify-start space-x-3 mt-6">
      <div className="flex flex-col justify-end items-center">
        <div className="w-8 h-8 rounded-full bg-primaryColor"></div>
      </div>

      <div className="rounded-xl bg-white py-2 px-3 text-black relative">
        <p className="absolute -top-5 left-2 text-[10px] text-white whitespace-nowrap">
          {name}
        </p>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default OtherMessage;
