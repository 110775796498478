import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import { BiSearch } from 'react-icons/bi';
import SongRecordings from './SongRecordings';
import ArtWorks from './ArtWorks';
import Projects from './Projects';
import Loading from '../../sparepages/Loading';
import SessionExpired from '../../components/SessionExpired';
import { useNavigate } from 'react-router-dom';
import SideSuccess from '../../components/SideSuccess';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';

const MyCollections = () => {
  const [step, setStep] = useState(1);
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(true);
  const [sideSuccess, setSideSuccess] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const [permission, setPermission] = useState('view');
  const [tokenExpired, setTokenExpired] = useState(false);

  const navigate = useNavigate();

  const dataFetchRef = useRef(false);

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'songrecording_collection',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  const showShowRecordings = () => {
    setStep(1);
  };

  const showArtwork = () => {
    setStep(2);
  };

  const showProjects = () => {
    setStep(3);
  };

  useEffect(() => {
    document.title = 'Cloud Storage - Overstood';

    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    checkPermission();

    // eslint-disable-next-line
  }, []);

  return (
    (permission === 'full' || permission === 'view') && (
      <div className="w-full h-full space-y-5 relative px-5 py-1">
        {/* background image */}
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* Success Box */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideSuccess ? 'right-4' : '-right-full'
          }`}
        >
          <SideSuccess name={'Successfully deleted!'} />
        </div>
        {/* Success Box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        <div className="w-full flex justify-between items-center">
          <div className="flex items-center">
            <button
              className={`w-36 py-2 rounded-tl-md rounded-bl-md hover:bg-secondaryColor hover:text-white ${
                step === 1
                  ? 'bg-secondaryColor text-white'
                  : 'bg-white text-black'
              }`}
              onClick={showShowRecordings}
            >
              Song Recording
            </button>
            <button
              className={`w-36 py-2 hover:bg-secondaryColor hover:text-white ${
                step === 2
                  ? 'bg-secondaryColor text-white'
                  : 'bg-white text-black'
              }`}
              onClick={showArtwork}
            >
              Artwork
            </button>
            <button
              className={`w-36 py-2 rounded-tr-md rounded-br-md hover:bg-secondaryColor hover:text-white ${
                step === 3
                  ? 'bg-secondaryColor text-white'
                  : 'bg-white text-black'
              }`}
              onClick={showProjects}
            >
              Projects
            </button>
          </div>

          <div className="flex items-center justify-between space-x-3 bg-white px-5 py-3 rounded-full text-black w-96">
            <input
              type="text"
              placeholder="Search Here"
              className="focus:outline-none"
              onChange={(e) => setSearch(e.target.value)}
            />

            <BiSearch className="text-xl" />
          </div>
        </div>

        {step === 1 && (
          <SongRecordings
            setLoading={setLoading}
            search={search}
            setSideSuccess={setSideSuccess}
            permission={permission}
          />
        )}
        {step === 2 && (
          <ArtWorks
            setLoading={setLoading}
            search={search}
            setSideSuccess={setSideSuccess}
            permission={permission}
          />
        )}
        {step === 3 && (
          <Projects
            setLoading={setLoading}
            search={search}
            setSideSuccess={setSideSuccess}
            permission={permission}
          />
        )}
      </div>
    )
  );
};

export default MyCollections;
