import React, { useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import { useNavigate } from 'react-router-dom';
import { BsFillShieldLockFill } from 'react-icons/bs';
import BackLink from '../../components/Layouts/BackLink';
import { useEffect } from 'react';
import moment from 'moment';
import SessionExpired from '../../components/SessionExpired';
import Loading from '../../sparepages/Loading';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';

const RequestMember = () => {
  const [requestedMember, setRequestedMember] = useState([]);

  let [cataLogs, setCataLogs] = useState([]);
  let [cataLogId, setCataLogId] = useState('');

  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const dataFetchRef = useRef(false);

  const navigate = useNavigate();

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'team_request_list',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          fetchCatalogs();
          cataLogId !== '' && fetchRequestMember();
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // chect permission to access to the next page
  const checkAccess = async (pagename) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: pagename,
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        console.log(data);

        // console.log(data.result);
        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          navigate('/teamMember/permission');
        } else {
          setSideAlert((prev) => (prev = true));

          setTimeout(() => {
            setSideAlert((prev) => (prev = false));
          }, 2000);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // fetching catalogs from the server
  const fetchCatalogs = async () => {
    try {
      const data = await fetchServer('/cat/userCata', 'GET');

      setCataLogs((cataLogs = data.result.data));
      setCataLogId((cataLogId = cataLogs[0].c1._id));
    } catch (error) {
      setSideAlert((prev) => (prev = true));

      setTimeout(() => {
        setSideAlert((prev) => (prev = false));
      }, 2000);
    }
  };

  // fetching requested members from the server
  const fetchRequestMember = async (catId) => {
    try {
      const body = {
        catId: catId,
      };

      const data = await fetchServer('/team/team_request_list', 'POST', body);

      if (data) {
        setLoading((loading) => (loading = false));
      }

      setRequestedMember(
        (prev) => (prev = data.result.data.filter((dt) => dt.c1 !== null))
      );
    } catch (error) {
      setSideAlert((prev) => (prev = true));

      setTimeout(() => {
        setSideAlert((prev) => (prev = false));
      }, 2000);
    }
  };

  // accepting or rejecting user
  const actionMember = async (requestId, userId, c3) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          requestId: requestId,
          catId: cataLogId,
          userId: userId,
          c3: c3,
        };

        const data = await fetchServer(
          '/team/team_request_list',
          'PATCH',
          body
        );

        if (data) {
          fetchRequestMember(cataLogId);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  useEffect(() => {
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;

    checkPermission();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    cataLogId !== '' && fetchRequestMember(cataLogId);

    // eslint-disable-next-line
  }, [cataLogId]);

  return (
    (permission === 'view' || permission === 'full') && (
      <div className="w-full h-full space-y-5 px-5 py-1">
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        <div className="w-full flex justify-end">
          <select
            className="bg-white rounded-xl p-2 outline-none w-36 text-black"
            defaultValue={cataLogId}
            onChange={(e) => {
              setCataLogId(e.target.value);
            }}
          >
            {cataLogs?.map((cataLog) => (
              <option key={cataLog.c1._id} value={cataLog.c1._id}>
                {cataLog.c1.c1}
              </option>
            ))}
          </select>
        </div>

        {requestedMember.length === 0 ? (
          <p className="text-3xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            No Requested Members Here Yet!!!
          </p>
        ) : (
          <div className="flex flex-col">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6 lg:px-8">
                <div className="h-[600px] 2xl:h-[750px] overflow-y-auto">
                  <table
                    className="min-w-full text-left text-sm font-light rounded-md border-spacing-y-2 border-separate"
                    cellSpacing={12}
                  >
                    <tbody>
                      {requestedMember.map((member) => (
                        <tr key={member._id} className="py-2">
                          <td className="whitespace-nowrap px-6 py-2 bg-white bg-opacity-20 backdrop-blur-lg rounded-tl-xl rounded-bl-xl">
                            <p className="font-bold">{member.c1.c1}</p>
                          </td>
                          <td className="whitespace-nowrap px-6 py-2 bg-white bg-opacity-20 backdrop-blur-lg">
                            {member.c1.c2}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2 bg-white bg-opacity-20 backdrop-blur-lg">
                            Contributor
                          </td>
                          <td className="whitespace-nowrap px-6 py-2 bg-white bg-opacity-20 backdrop-blur-lg">
                            {moment(member.createdAt).calendar()}
                          </td>
                          <td className="whitespace-nowrap py-2 bg-white bg-opacity-20 backdrop-blur-lg">
                            <button
                              onClick={() =>
                                actionMember(member._id, member.c1._id, 1)
                              }
                              className="text-sm font-semibold bg-primaryColor rounded-md px-3 py-2"
                            >
                              Accept
                            </button>
                          </td>
                          <td className="whitespace-nowrap py-2 bg-white bg-opacity-20 backdrop-blur-lg rounded-tr-xl rounded-br-xl">
                            <button
                              onClick={() =>
                                actionMember(member._id, member.c1._id, 2)
                              }
                              className="text-sm font-semibold bg-secondaryRed rounded-md px-3 py-2 "
                            >
                              Reject
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="absolute bottom-5 pr-10 flex w-full justify-between items-center">
          <BackLink to={`/teamMember`} />
          <button
            onClick={() => checkAccess('permission')}
            className="bg-primaryColor text-white px-5 py-2 rounded-md flex space-x-3 items-center"
          >
            <BsFillShieldLockFill />
            <span>Permission</span>
          </button>
        </div>
      </div>
    )
  );
};

export default RequestMember;
