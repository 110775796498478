import React, { useEffect, useState } from 'react';
import TeamMemberPhoto from '../../assets/images/teamMember.png';
import SingleChat from './SingleChat';

const ChatMembers = ({ owner, teamMembers, userId }) => {
  const [single, setSingle] = useState(0);
  const [receiverId, setReceiverId] = useState(null);

  const [receiverName, setReceiverName] = useState(null);
  const [receiverRole, setReceiverRole] = useState(null);

  return (
    <>
      {single === 0 && (
        <div className="max-h-full pt-24 overflow-y-auto grid grid-cols-2 gap-5">
          {owner !== null && owner._id !== userId && (
            <div
              key={owner._id}
              className="flex items-center space-x-5 bg-white bg-opacity-10 backdrop-blur-lg rounded-lg px-5 py-8 h-24 relative cursor-pointer transition-all duration-300 hover:bg-[rgba(255,255,255,0.2)]"
              onClick={() => {
                setReceiverId((prev) => (prev = owner._id));
                setSingle((prev) => (prev = 1));
                setReceiverName((prev) => (prev = owner.c1));
                setReceiverRole((prev) => (prev = 'Owner'));
              }}
            >
              <div className="w-12">
                <img src={TeamMemberPhoto} alt="" />
              </div>

              <div>
                <p className="text-xs font-semibold">{owner.c1}</p>
                <p className="text-[10px]">Owner</p>
              </div>
            </div>
          )}

          {teamMembers
            ?.filter((member) => member._id !== userId)
            .map((member) => (
              <div
                key={member._id}
                className="flex items-center space-x-5 bg-white bg-opacity-10 backdrop-blur-lg rounded-lg px-5 py-8 h-24 relative cursor-pointer transition-all duration-300 hover:bg-[rgba(255,255,255,0.2)]"
                onClick={() => {
                  setReceiverId((prev) => (prev = member._id));
                  setSingle((prev) => (prev = 1));
                  setReceiverName((prev) => (prev = member.c1));
                  setReceiverRole(
                    (prev) => (prev = member.c4 ? member.c4.c1 : 'Contributor')
                  );
                }}
              >
                <div className="w-12">
                  <img src={TeamMemberPhoto} alt="" />
                </div>

                <div>
                  <p className="text-xs font-semibold">{member.c1}</p>
                  <p className="text-[10px]">
                    {member.c4 ? member.c4.c1 : 'Contributor'}
                  </p>
                </div>
              </div>
            ))}
        </div>
      )}
      {single === 1 && (
        <SingleChat
          userId={userId}
          setSingle={setSingle}
          receiverId={receiverId}
          receiverName={receiverName}
          receiverRole={receiverRole}
        />
      )}
    </>
  );
};

export default ChatMembers;
