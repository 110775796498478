import React, { useState } from 'react';
import LoginBg from '../../assets/images/loginbg.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loading from '../../sparepages/Loading';
import SideAlert from '../../components/SideAlert';
import SideSuccess from '../../components/SideSuccess';

const Register = () => {
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [careerRole, setCareerRole] = useState('');

  const [sideAlert, setSideAlert] = useState(false);
  const [sideSuccess, setSideSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('Something went wrong!!!');

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading((prev) => (prev = true));

    try {
      // Send login request to the backend
      const data = await axios.post('/auth/register', {
        c1: username,
        c2: email,
        c3: password,
        c5: careerRole,
      });

      console.log(data);

      if (data) {
        setLoading((prev) => (prev = false));
      }

      if (data.result.error) {
        data.result.error.toLowerCase().includes('user already exist') &&
          setErrorText('User already exists!!!');

        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);

        return;
      }
      localStorage.setItem('id', data.result.data.RegisterData._id);

      setSideAlert((prev) => (prev = true));

      setTimeout(() => {
        setSideSuccess((prev) => (prev = false));

        setTimeout(() => {
          navigate('/login/joinArtists');
        }, 0);
      }, 2000);
    } catch (error) {
      console.error('Authentication failed:', error.response.data.error);
    }
  };

  return (
    <div className="w-screen h-screen relative bg-black">
      <div>
        <img
          src={LoginBg}
          alt="Login Background"
          className="object-center object-cover"
        />
      </div>

      {/* Error Box */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideAlert ? 'right-4' : '-right-full'
        }`}
      >
        <SideAlert name={errorText} />
      </div>
      {/* Error Box */}

      {/* Success Box */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideSuccess ? 'right-4' : '-right-full'
        }`}
      >
        <SideSuccess name={'Register Successful!'} />
      </div>
      {/* Success Box */}

      {/* loading box */}
      {loading && (
        <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
          <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
            <Loading />
          </div>
        </div>
      )}
      {/* loading box */}

      <div className="w-3/4 md:w-2/5 py-16 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-20 backdrop-blur-lg rounded-xl flex flex-col space-y-8 items-center">
        <p className="text-xl font-semibold mb-3 text-white">Create Account</p>
        <form
          className="w-full flex flex-col space-y-8 items-center"
          onSubmit={handleFormSubmit}
        >
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            required
            className="w-3/4 md:w-3/5 py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            className="w-3/4 md:w-3/5 py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
            className="w-3/4 md:w-3/5 py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
          />
          <input
            type="text"
            placeholder="Career Role Name"
            value={careerRole}
            required
            onChange={(e) => setCareerRole(e.target.value)}
            className="w-3/4 md:w-3/5 py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
          />

          <button
            type="submit"
            className="w-3/4 md:w-3/5 py-2 px-3 text-black text-sm bg-white rounded-xl active:outline-none focus:outline-none"
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
