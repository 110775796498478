import React, { useEffect, useState } from 'react';
import ArchievedItem from '../../components/Archieved/ArchievedItem';
import { fetchServer } from '../../shares/helper';

const SettingsArchieved = ({ setLoading }) => {
  const [archieved, setArchieved] = useState([]);

  // fetching archieved data from the server
  const fetchArchieved = async () => {
    try {
      const data = await fetchServer('/setting/archived', 'GET');

      // console.log(data);

      if (data) {
        setLoading((prev) => (prev = false));
      }

      if (data.result.error === '') {
        setArchieved((prev) => (prev = data.result.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading((prev) => (prev = true));
    fetchArchieved();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full flex flex-col space-y-3">
      <ArchievedItem archieved={archieved} />
    </div>
  );
};

export default SettingsArchieved;
