import React, { useEffect, useRef, useState } from 'react';
import BackLink from '../../components/Layouts/BackLink';
import { AiFillSave } from 'react-icons/ai';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import Loading from '../../sparepages/Loading';
import SideAlert from '../../components/SideAlert';
import { useNavigate } from 'react-router-dom';
import SessionExpired from '../../components/SessionExpired';
import SideSuccess from '../../components/SideSuccess';
import { fetchServer } from '../../shares/helper';

const DesignArtwork = () => {
  const [imgSrc, setImgSrc] = useState(null);
  const [imgError, setImgError] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const [photo, setPhoto] = useState(null);
  const [artWorkName, setArtWorkName] = useState('');
  const [artWorkDesc, setArtWorkDesc] = useState('');

  const [loading, setLoading] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const [permission, setPermission] = useState('view');
  const [tokenExpired, setTokenExpired] = useState(false);

  const [confirmBox, setConfirmBox] = useState(false);
  const [sideSuccess, setSideSuccess] = useState(false);

  const navigate = useNavigate();

  const dataFetchRef = useRef(false);

  const reader = new FileReader();

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'art_work',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        // console.log(data.result);

        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // handling seleted image
  const handlePreviewImage = (element) => {
    if (!element.target.files[0]) return;
    reader.readAsDataURL(element.target.files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = () => {
        const { height, width } = image;
        if (!(height >= 1500 && width >= 1500 && height === width)) {
          setImgSrc(null);
          setPhoto(null);
          setShowImage(false);
          setImgError(true);
          return false;
        }
        setImgError(false);
        setShowImage(true);
        setImgSrc(URL.createObjectURL(element.target.files[0]));
        setPhoto(element.target.files[0]);
        return true;
      };
    };
  };

  // upload file datas to the server
  const onFileUpload = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const formData = new FormData();

        formData.append('photo', photo);
        formData.append('c1', artWorkName);
        formData.append('c2', artWorkDesc);

        const data = await fetchServer('/art/upload_artwork', 'POST', formData);

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        console.log(data);

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prev) => (prev = true));
        } else {
          setSideSuccess((prev) => (prev = true));

          setTimeout(() => {
            setSideSuccess((prev) => (prev = false));
            setImgSrc(null);
            setPhoto(null);
            setShowImage(false);

            setTimeout(() => {
              navigate('/artworks');
            }, 0);
          }, 2000);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prev) => (prev = true));
    }
  };

  // to trigger permission check
  useEffect(() => {
    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    checkPermission();

    // eslint-disable-next-line
  }, []);

  return (
    (permission === 'full' || permission === 'view') && (
      <div className="w-full h-full p-5">
        {/* background image */}
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* Success Box */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideSuccess ? 'right-4' : '-right-full'
          }`}
        >
          <SideSuccess name={'Upload Successful!'} />
        </div>
        {/* Success Box */}

        {/* Side Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong!" />
        </div>
        {/* Side Alert */}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Confirm Box */}
        {confirmBox && (
          <div
            className="absolute top-0 left-0 w-screen h-screen z-40"
            onClick={() => {
              setConfirmBox(false);
            }}
          ></div>
        )}

        {confirmBox && (
          <div className="absolute w-[600px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded-xl bg-white z-50 text-black text-center">
            <p className="my-3 text-xl text-tertiary">
              Are you sure you want to upload this artwork!
            </p>

            {(photo === null || artWorkName === '' || artWorkDesc === '') && (
              <p className="text-xl text-secondaryRed">Warning</p>
            )}

            {photo === null && (
              <p className="text-secondaryRed text-xs">
                You need to select artwork file in the artwork box.
              </p>
            )}

            {artWorkName === '' && (
              <p className="text-secondaryRed text-xs">
                Artwork's name is empty.
              </p>
            )}

            {artWorkDesc === '' && (
              <p className="text-secondaryRed text-xs">
                Artwork's description is empty.
              </p>
            )}

            <div className="w-full flex items-center justify-between mt-10 px-28">
              <button
                onClick={() => setConfirmBox(false)}
                className="px-10 py-3 rounded-lg bg-[rgba(0,0,0,0.2)]"
              >
                Cancel
              </button>

              {photo === null || artWorkName === '' || artWorkDesc === '' ? (
                <button className="px-10 py-3 rounded-lg bg-tertiary text-white cursor-not-allowed">
                  Next
                </button>
              ) : (
                <button
                  onClick={() => {
                    setConfirmBox(false);
                    onFileUpload();
                  }}
                  className="px-10 py-3 rounded-lg bg-tertiary text-white"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        )}
        {/* Confirm Box */}

        <p className="text-3xl">Create Artwork</p>

        <div className="flex justify-evenly mt-20">
          <div className="flex flex-col items-center space-y-5 p-10 bg-white bg-opacity-10 backdrop-blur-lg rounded-xl">
            <label
              htmlFor={`${permission === 'full' ? 'artworkImage' : ''}`}
              className="w-96 h-96 bg-white rounded-md overflow-hidden cursor-pointer flex justify-center items-center"
            >
              {showImage && (
                <img
                  src={imgSrc}
                  alt="Preview"
                  className="w-full h-full object-cover"
                />
              )}
              {imgError && (
                <p className="text-secondaryRed font-bold px-5">
                  Image Size must be greater than or equal to 1500 x 1500
                  (pixels) and also must be square.
                </p>
              )}
            </label>

            <input
              type="file"
              id="artworkImage"
              className="hidden"
              accept="image/*"
              onChange={handlePreviewImage}
            />
          </div>

          <div className="flex flex-col space-y-5 p-10 bg-white bg-opacity-10 backdrop-blur-lg rounded-xl">
            <div className="flex flex-col space-y-2">
              <label htmlFor="">Artwork's Name</label>
              <input
                type="text"
                className="bg-secondaryBg rounded-xl p-2 outline-none w-96"
                onChange={(e) => setArtWorkName(e.target.value)}
              />
            </div>

            <div className="flex flex-col space-y-2">
              <label htmlFor="">Description</label>
              <textarea
                type="text"
                className="bg-secondaryBg rounded-xl p-2 outline-none w-96 h-44 resize-none"
                onChange={(e) => setArtWorkDesc(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="absolute bottom-5 pr-10 flex w-full justify-between items-center">
          <BackLink to={'/artworks'} />

          {permission === 'full' && (
            <button
              onClick={() => setConfirmBox((prev) => (prev = true))}
              className="bg-primaryColor text-white px-5 py-2 rounded-md flex space-x-3 items-center"
            >
              <AiFillSave />
              <span>Save</span>
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default DesignArtwork;
