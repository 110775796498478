import React from 'react';
import ErrorGif from '../../assets/images/errorgif.gif';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const ErrorPage = () => {
  return (
    <div className="w-screen h-screen bg-black flex flex-col justify-center items-center">
      <img src={ErrorGif} alt="" />

      <Link
        to={'/music'}
        className="text-5xl text-[#E2DA0E] rounded-md mt-10 underline tracking-widest animate-bounce"
      >
        <AiOutlineArrowLeft />
      </Link>
    </div>
  );
};

export default ErrorPage;
