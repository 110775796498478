import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../redux/loginSlice';
import { Navigate } from 'react-router-dom';

const RouteGuardAuth = ({ children }) => {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.login);

  if (
    localStorage.getItem('name') === '' &&
    localStorage.getItem('token') === ''
  ) {
    dispatch(login(false));
  } else {
    localStorage.getItem('name') && localStorage.getItem('token')
      ? dispatch(login(true))
      : dispatch(login(false));
  }

  if (!loggedIn.value) return children;
  else return <Navigate to="/music" replace />;
};

export default RouteGuardAuth;
