import React, { useState } from 'react';
import LoginBg from '../../assets/images/loginbg.png';
import SideAlert from '../../components/SideAlert';
import Loading from '../../sparepages/Loading';
import SideSuccess from '../../components/SideSuccess';
import { fetchServer } from '../../shares/helper';
import SessionExpired from '../../components/SessionExpired';
import { useNavigate } from 'react-router-dom';

const Otp = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  //   const [errorText, setErrorText] = useState('Something went wrong');

  const [loading, setLoading] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);
  const [sideSuccess, setSideSuccess] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem('token') !== '' && localStorage.getItem('token')) {
      setLoading((prev) => (prev = true));
      try {
        const body = {};

        name !== '' && (body.c1 = name);
        password !== '' && (body.c3 = password);

        const data = await fetchServer('/auth/otp', 'POST', body);

        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.toLowerCase().includes('token expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.error !== '') {
          setSideAlert((prev) => (prev = true));

          setTimeout(() => {
            setSideAlert((prev) => (prev = false));
          }, 2000);
        } else {
          setSideSuccess((prev) => (prev = true));

          setTimeout(() => {
            setSideSuccess((prev) => (prev = false));
            localStorage.removeItem('token');

            navigate('/login');
          }, 2000);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prev) => (prev = true));
    }
  };

  return (
    <div className="w-screen h-screen bg-black">
      <div className="w-full h-1/2">
        <img
          src={LoginBg}
          alt="Login Background"
          className="object-center object-cover w-full h-full"
        />
      </div>

      {/* if token expired */}
      {tokenExpired && <SessionExpired />}

      {/* Error Box */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideAlert ? 'right-4' : '-right-full'
        }`}
      >
        <SideAlert name={'Something went wrong'} />
      </div>
      {/* Error Box */}

      {/* Success Box */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideSuccess ? 'right-4' : '-right-full'
        }`}
      >
        <SideSuccess name={'Login Successful!'} />
      </div>
      {/* Success Box */}

      {/* loading box */}
      {(sideSuccess || loading) && (
        <div className="w-screen h-screen absolute -top-24 left-0 z-40"></div>
      )}

      {/* loading box */}
      {loading && (
        <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
          <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
            <Loading />
          </div>
        </div>
      )}
      {/* loading box */}

      <div className="w-3/4 md:w-2/5 py-16 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-20 backdrop-blur-lg rounded-xl flex flex-col space-y-8 items-center">
        <p className="text-xl font-semibold mb-3 text-white">
          Fill your new name and new password
        </p>
        <form
          className="w-full flex flex-col space-y-8 items-center"
          onSubmit={handleFormSubmit}
        >
          <input
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-3/4 md:w-3/5 py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
          />
          <input
            type="password"
            placeholder="Enter Your New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-3/4 md:w-3/5 py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
          />

          <button className="w-3/4 md:w-3/5 py-2 px-3 text-black text-sm bg-white rounded-xl active:outline-none focus:outline-none">
            Next
          </button>
        </form>
      </div>
    </div>
  );
};

export default Otp;
