import React from 'react';
import { TiTick } from 'react-icons/ti';

const SideSuccess = (props) => {
  return (
    <div className="px-10 py-3 rounded-lg bg-white flex space-x-3 items-center border-2 border-primaryColor text-primaryColor">
      <div className="text-3xl">
        <TiTick />
      </div>
      <p>{props.name}</p>
    </div>
  );
};

export default SideSuccess;
