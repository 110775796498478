import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import MusicData from '../../assets/images/musicdata.png';
import {
  AiFillQuestionCircle,
  AiFillSave,
  AiOutlineWarning,
} from 'react-icons/ai';
import SideSuccess from '../../components/SideSuccess';
import Loading from '../../sparepages/Loading';
import { useNavigate } from 'react-router-dom';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';
import SessionExpired from '../../components/SessionExpired';

const Profile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bandName, setBandName] = useState('');
  const [caeIpi, setCaeIpi] = useState('');
  const [isrc, setIsrc] = useState('');
  const [pros, setPros] = useState('');

  const [loading, setLoading] = useState(true);
  const [confirmBox, setConfirmBox] = useState(false);
  const [sideSuccess, setSideSuccess] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [status, setStatus] = useState(0);

  const [form, setForm] = useState(false);

  const dataFetchRef = useRef(false);

  const navigate = useNavigate();

  // fetching profile data if no profile data push to preview page
  const fetchProfile = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const data = await fetchServer('/profile/user_profile', 'GET');

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.data) {
          navigate('/profile/previewProfile');
        } else {
          setLoading((prev) => (prev = false));
          setForm((prev) => (prev = true));
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prev) => (prev = true));
    }
  };

  // upload profile data to server
  const uploadUserProfile = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const body = {
          c2: firstName,
          c3: lastName,
          c4: bandName,
          c8: status,
        };
        caeIpi !== '' && (body.c5 = caeIpi);
        isrc !== '' && (body.c6 = isrc);
        pros !== '' && (body.c7 = pros);

        const data = await fetchServer('/profile/user_profile', 'POST', body);

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        } else {
          setSideSuccess((prev) => (prev = true));

          setTimeout(() => {
            setSideSuccess((prev) => (prev = false));

            setTimeout(() => {
              navigate('/profile/previewProfile');
            }, 0);
          }, 2000);
        }
        // console.log(data);
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prev) => (prev = true));
    }
  };

  useEffect(() => {
    document.title = 'Profile - Overstood';

    if (dataFetchRef.current) return;
    dataFetchRef.current = true;
    fetchProfile();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full h-full space-y-5 px-5 py-1">
      <img
        src={BackgroundImageZZ}
        className="absolute top-0 left-0 w-full h-full bg-center -z-10"
        alt=""
      />

      {/* if token expired */}
      {tokenExpired && <SessionExpired />}

      {/* Success Box */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideSuccess ? 'right-4' : '-right-full'
        }`}
      >
        <SideSuccess name={'Upload Successful!'} />
      </div>
      {/* Success Box */}

      {/* Failure Alert */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideAlert ? 'right-4' : '-right-full'
        }`}
      >
        <SideAlert name="Something went wrong" />
      </div>
      {/* Failure Alert */}

      {/* loading box */}
      {loading && (
        <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
          <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
            <Loading />
          </div>
        </div>
      )}
      {/* loading box */}

      {/* Confirm Box */}
      {confirmBox && (
        <div
          className="absolute top-0 left-0 w-screen h-screen z-40"
          onClick={() => {
            setConfirmBox(false);
          }}
        ></div>
      )}

      {confirmBox && (
        <div className="absolute w-[600px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded-xl bg-white z-50 text-black text-center">
          <div className="w-full flex justify-center text-7xl text-tertiary">
            <AiOutlineWarning />
          </div>
          <p className="my-3 text-3xl text-tertiary">Warning!</p>
          <p>
            You can no longer edit after save . <br />
            Are you Sure ?
          </p>
          {(firstName === '' || lastName === '' || bandName === '') && (
            <p className="text-xs text-secondaryRed">
              You need to fill out the fields of first name, last name and band
              name
            </p>
          )}
          <div className="w-full flex items-center justify-between mt-10 px-28">
            <button
              onClick={() => setConfirmBox(false)}
              className="px-10 py-3 rounded-lg bg-[rgba(0,0,0,0.2)]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setConfirmBox(false);
                uploadUserProfile();
              }}
              className="px-10 py-3 rounded-lg bg-tertiary text-white"
            >
              Next
            </button>
          </div>
        </div>
      )}
      {/* Confirm Box */}

      {form === true && (
        <>
          <div className="w-full flex flex-col items-center absolute top-1/2 left-0 -translate-y-1/2">
            <div className="flex flex-col w-2/3 py-8 rounded-lg items-center bg-white bg-opacity-10 backdrop-blur-lg">
              <div className="w-24 h-24 rounded-full flex items-center justify-center bg-white text-black">
                <img src={MusicData} alt="" />
              </div>

              <div className="flex flex-col items-center relative space-y-2 mt-5">
                <div className="flex space-x-4 w-[500px]">
                  <div className="flex flex-col space-y-2 w-1/2">
                    <label htmlFor="">First Name</label>
                    <input
                      type="text"
                      className="bg-secondaryBg rounded-xl p-2 outline-none w-full"
                      onChange={(e) =>
                        setFirstName((prev) => (prev = e.target.value))
                      }
                    />
                  </div>

                  <div className="flex flex-col space-y-2 w-1/2">
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      className="bg-secondaryBg rounded-xl p-2 outline-none w-full"
                      onChange={(e) =>
                        setLastName((prev) => (prev = e.target.value))
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-col space-y-2 w-[500px]">
                  <label htmlFor="">Band Name</label>
                  <input
                    type="text"
                    className="bg-secondaryBg rounded-xl p-2 outline-none w-full"
                    onChange={(e) =>
                      setBandName((prev) => (prev = e.target.value))
                    }
                  />
                </div>

                <div className="flex flex-col space-y-2 w-[500px]">
                  <label htmlFor="">CAE/IPI</label>
                  <input
                    type="text"
                    className="bg-secondaryBg rounded-xl p-2 outline-none w-full"
                    onChange={(e) =>
                      setCaeIpi((prev) => (prev = e.target.value))
                    }
                  />
                </div>

                <div className="flex flex-col space-y-2 w-[500px]">
                  <label htmlFor="">ISRC</label>
                  <input
                    type="text"
                    className="bg-secondaryBg rounded-xl p-2 outline-none w-full"
                    onChange={(e) => setIsrc((prev) => (prev = e.target.value))}
                  />
                </div>

                <div className="flex flex-col space-y-2 w-[500px]">
                  <label htmlFor="">PROs</label>
                  <input
                    type="text"
                    className="bg-secondaryBg rounded-xl p-2 outline-none w-full"
                    onChange={(e) => setPros((prev) => (prev = e.target.value))}
                  />
                </div>

                <div className="w-full flex justify-end pt-4">
                  <button
                    onClick={() => {
                      setStatus((prev) => (prev = 0));
                      setConfirmBox((prev) => (prev = true));
                    }}
                    className="py-2 px-4 bg-secondaryBlue rounded-lg flex items-center space-x-3"
                  >
                    <span>Ask to admin</span>
                    <AiFillQuestionCircle />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-5 pr-16 flex w-full justify-end items-center">
            <button
              onClick={() => {
                setStatus((prev) => (prev = 1));
                setConfirmBox((prev) => (prev = true));
              }}
              className="bg-primaryColor text-white px-5 py-2 rounded-md flex space-x-3 items-center"
            >
              <AiFillSave />
              <span>Save</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
