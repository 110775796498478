import React, { useEffect, useState } from 'react';
import OtherMessage from './OtherMessage';
import YourMessage from './YourMessage';
import { BsFillSendFill } from 'react-icons/bs';
import axios from 'axios';
// import io from 'socket.io-client';

// const ENDPOINT = 'http://localhost:5000';
// const socket = io.connect(ENDPOINT);

const TeamChat = ({ teamMembers, cataLogId, userId, owner }) => {
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState('');

  const fetchTeamChats = async () => {
    try {
      const token = localStorage.getItem('token');

      const body = {
        catId: cataLogId,
      };

      const data = await axios.post('/chat/getTeamMessage', body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setChats((prev) => (prev = data.result));

      // console.log(chats);
    } catch (error) {}
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const sendMessage = async (e) => {
    if (e.key === 'Enter') {
      if (message === '') {
        return;
      }
      try {
        const token = localStorage.getItem('token');

        const body = {
          catId: cataLogId,
          senderId: userId,
          content: message,
        };

        const data = await axios.post('/chat/sendMessage', body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // socket.emit('message', data.result);

        if (data) {
          fetchTeamChats();
        }

        clearMessage();

        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const clearMessage = () => {
    setMessage('');
  };

  useEffect(() => {
    cataLogId && fetchTeamChats();
  }, [cataLogId]);

  // useEffect(() => {
  //   socket.on('messageResponse', (data) => console.log(data));
  // });
  return (
    <>
      <div className="h-full pt-24 flex flex-col-reverse overflow-y-auto pb-24 relative no-scrollbar">
        {chats?.length > 0 &&
          chats?.map((chat) =>
            chat.c2?._id === userId ? (
              <YourMessage key={chat._id} content={chat.c4} />
            ) : (
              <OtherMessage
                key={chat._id}
                name={chat.c2?.c1}
                content={chat.c4}
              />
            )
          )}
      </div>
      <div className="w-full h-24 py-5 flex justify-center items-center absolute bottom-0 pr-10 bg-darkBlue">
        <div className="w-full h-full px-5 rounded-lg bg-secondaryGray flex items-center justify-between space-x-5">
          <input
            type="text"
            className="w-full bg-secondaryGray focus:outline-none"
            value={message}
            onChange={handleChange}
            onKeyDown={sendMessage}
          />
          <button className="text-2xl">
            <BsFillSendFill />
          </button>
        </div>
      </div>
    </>
  );
};

export default TeamChat;
