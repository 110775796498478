import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import TeamMemberPhoto from '../../assets/images/teamMember.png';
import { FaCrown } from 'react-icons/fa';
import { BsFillShieldLockFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { IoIosPeople } from 'react-icons/io';
import { AiFillMessage } from 'react-icons/ai';
import Loading from '../../sparepages/Loading';
import Chat from './Chat';
import { fetchServer } from '../../shares/helper';
import SessionExpired from '../../components/SessionExpired';
import SideAlert from '../../components/SideAlert';

const TeamMember = () => {
  let [cataLogs, setCataLogs] = useState([]);
  let [cataLogId, setCataLogId] = useState('');

  const [permission, setPermission] = useState('other');

  const [teamMembers, setTeamMembers] = useState([]);
  const [owner, setOwner] = useState(null);

  const [messageBox, setMessageBox] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const userId = localStorage.getItem('userId');

  const navigate = useNavigate();

  const dataFetchRef = useRef(false);

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'team_member_list',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        console.log(data);

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        fetchCatalogs();

        if (data.result.msg === 0 || data.result.msg === 2) {
          setPermission((prevState) => (prevState = 'other'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'owner'));
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // chect permission to access to the next page
  const checkAccess = async (pagename) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: pagename,
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        // console.log(data.result);
        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          if (pagename.includes('request')) {
            navigate('requestMember');
          } else {
            navigate('permission');
          }
        } else {
          setSideAlert((prev) => (prev = true));

          setTimeout(() => {
            setSideAlert((prev) => (prev = false));
          }, 2000);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // fetch catalogs from the server
  const fetchCatalogs = async () => {
    try {
      const data = await fetchServer('/cat/userCata', 'GET');

      setCataLogs((cataLogs = data.result.data));
      setCataLogId((cataLogId = cataLogs[0].c1._id));

      // console.log(data);
    } catch (error) {
      setSideAlert((prev) => (prev = true));

      setTimeout(() => {
        setSideAlert((prev) => (prev = false));
      }, 2000);
    }
  };

  // fetching members of a specific catalog from the server
  const fetchMembers = async (catId) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const body = {
          catId: catId,
        };

        const data = await fetchServer('/team/team_member_list', 'POST', body);

        // console.log(data);

        if (data) {
          setLoading((loading) => (loading = false));
        }

        if (data.result.data.Members !== null) {
          setTeamMembers((prev) => (prev = data.result.data.Members.c2));
        } else {
          setTeamMembers((prev) => (prev = []));
        }

        // console.log(data.result.data.Owner.c2);

        if (data.result.data.Owner.c2) {
          setOwner((prev) => (prev = data.result.data.Owner.c2));
        } else {
          setOwner((prev) => (prev = null));
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  useEffect(() => {
    document.title = 'Team Member - Overstood';

    if (dataFetchRef.current) return;

    dataFetchRef.current = true;

    checkPermission();

    // fetchCatalogs();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    cataLogId !== '' && fetchMembers(cataLogId);

    // eslint-disable-next-line
  }, [cataLogId]);

  return (
    (permission === 'owner' || permission === 'other') && (
      <div className="w-full h-full space-y-5 px-5 py-1">
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        <div className="w-full flex justify-end">
          <select
            className="bg-white rounded-xl p-2 outline-none w-36 text-black"
            defaultValue={cataLogId}
            onChange={(e) => {
              setCataLogId(e.target.value);
            }}
          >
            {cataLogs?.map((cataLog) => (
              <option key={cataLog.c1._id} value={cataLog.c1._id}>
                {cataLog.c1.c1}
              </option>
            ))}
          </select>
        </div>

        <div className="w-[900px] max-h-[580px] 2xl:max-h-[730px] px-10 overflow-y-auto customScrollY grid grid-cols-2 gap-10 absolute left-1/2 -translate-x-1/2">
          {owner !== null && (
            <div
              key={owner._id}
              className="flex items-center space-x-5 bg-white bg-opacity-10 backdrop-blur-lg rounded-lg px-5 py-8 w-96 h-40 relative"
            >
              <img src={TeamMemberPhoto} alt="" />

              <div>
                <p className="text-lg font-semibold">{owner.c1}</p>
                <p className="text-sm">Owner</p>
              </div>

              <div className="absolute top-2 right-2 text-xl text-tertiary">
                <FaCrown />
              </div>
            </div>
          )}

          {teamMembers.length > 0 &&
            teamMembers?.map((member) => (
              <div
                key={member._id}
                className="flex items-center space-x-5 bg-white bg-opacity-10 backdrop-blur-lg rounded-lg px-5 py-8 w-96 h-40 relative"
              >
                <img src={TeamMemberPhoto} alt="" />

                <div>
                  <p className="text-lg font-semibold">{member.c1}</p>
                  <p className="text-sm">
                    {member.c4 ? member.c4.c1 : 'Contributor'}
                  </p>
                </div>
              </div>
            ))}
        </div>

        <div
          className={`fixed -top-5 h-full w-[600px]  bg-darkBlue z-10 transition-all duration-700 flex flex-col justify-between ${
            messageBox ? 'right-0' : '-right-[600px]'
          }`}
        >
          <button
            onClick={() => setMessageBox(!messageBox)}
            className="absolute w-20 h-20 rounded-full top-1/2 -translate-y-1/2 -left-10 -z-10 transition-all duration-1000 flex items-center pl-2 bg-secondaryColor"
          >
            <AiFillMessage className="text-3xl" />
          </button>

          <Chat
            userId={userId}
            owner={owner}
            teamMembers={teamMembers}
            cataLogId={cataLogId}
          />
        </div>

        {permission === 'owner' && (
          <div className="absolute bottom-5 pr-10 flex w-full justify-end items-center space-x-4">
            <button
              onClick={() => checkAccess('team_request_list')}
              className="bg-secondaryColor text-white px-5 py-2 rounded-md flex space-x-3 items-center"
            >
              <IoIosPeople />
              <span>Request Member</span>
            </button>
            <button
              onClick={() => checkAccess('permission')}
              className="bg-primaryColor text-white px-5 py-2 rounded-md flex space-x-3 items-center"
            >
              <BsFillShieldLockFill />
              <span>Permission</span>
            </button>
          </div>
        )}
      </div>
    )
  );
};

export default TeamMember;
