import React from 'react';
import MovieBg from '../../assets/images/moviebg.png';
import LoginArtist from '../../assets/images/loginartist.png';
import { AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Movie = () => {
  //   let { id } = useParams();

  const catalogs = [];

  for (let index = 0; index < 10; index++) {
    catalogs.push(
      <div className="w-60 pb-10 pt-16 mt-16 rounded-xl text-center relative bg-black bg-opacity-10 backdrop-blur-lg text-white">
        <div className="flex justify-center absolute left-1/2 -translate-x-1/2 -top-1/2 translate-y-1/2">
          <img src={LoginArtist} alt="Login Artists" className="w-40" />
        </div>

        <p className="font-semibold text-xl mt-8 mb-5">Boy with Uke</p>
        <p className="text-xs"></p>
        <Link
          to={`view/${index + 1}`}
          className=" bg-secondaryColor px-4 py-1 rounded-lg"
        >
          View
        </Link>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen p-10">
      <img
        src={MovieBg}
        className="absolute top-0 left-0 w-full h-full bg-center -z-10"
        alt=""
      />

      <div className="w-full h-full py-16 px-10 bg-white bg-opacity-20 backdrop-blur-lg rounded-xl flex flex-col space-y-8 items-center">
        <div className="w-3/4 rounded-full bg-white px-4 py-2 space-x-4 flex items-center justify-center">
          <input
            type="text"
            className="w-full focus:outline-none bg-white"
            placeholder="Search Here..."
          />
          <AiOutlineSearch className="text-3xl" />
        </div>
        <div className="grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 place-items-center w-full h-[600px] overflow-y-auto">
          {catalogs}
        </div>
      </div>
    </div>
  );
};

export default Movie;
