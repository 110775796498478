import React, { useEffect, useRef, useState } from 'react';
import { IoMdDownload } from 'react-icons/io';
import { BsFillTrashFill } from 'react-icons/bs';
import moment from 'moment';
import { fetchServer } from '../../shares/helper';
import SessionExpired from '../../components/SessionExpired';
import SideAlert from '../../components/SideAlert';

const ArtWorks = ({ setLoading, search, setSideSuccess, permission }) => {
  let [artworks, setArtworks] = useState([]);

  const [confirmBox, setConfirmBox] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const [artId, setArtId] = useState(null);

  const dataFetchRef = useRef(false);

  // fetching artwork data from the server
  const fetchArtworks = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const data = await fetchServer('/cloud/artwork_collection', 'GET');

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        // console.log(data);
        setArtworks((artworks = data.result.data));
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // downlaod the artwork
  const downloadArtwork = async (artId) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const mimeToExtension = {
          'image/jpeg': 'jpg',
          'image/png': 'png',
          'image/gif': 'gif',
          'image/bmp': 'bmp',
          'image/webp': 'webp',
          'image/tiff': 'tiff',
          'image/svg+xml': 'svg',
          'image/x-icon': 'ico',
          'image/vnd.adobe.photoshop': 'psd',
          'image/heic': 'heic',
          'image/heif': 'heif',
        };
        const body = {
          artId: artId,
        };

        const data = await fetchServer(
          '/cloud/artwork_collection',
          'POST',
          body,
          true
        );

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        const url = window.URL.createObjectURL(new Blob([data.data]));
        const contentDisposition = data.headers['content-disposition'];
        const fileName = contentDisposition
          ? contentDisposition.split('filename=')[1].trim()
          : 'file';
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          fileName + '.' + mimeToExtension[data.headers['content-type']]
        );
        link.click();
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // delete artwork
  const deleteArtwork = async (artId) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const body = {
          artId: artId,
        };

        const data = await fetchServer(
          '/cloud/artwork_collection',
          'DELETE',
          body
        );

        fetchArtworks();

        if (data) {
          setLoading((prevState) => (prevState = false));

          setSideSuccess((prev) => (prev = true));

          setTimeout(() => {
            setSideSuccess((prev) => (prev = false));
          }, 2000);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // to trigger fetchartworks
  useEffect(() => {
    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    fetchArtworks();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Confirm Box */}
      {confirmBox && (
        <div
          className="absolute top-0 left-0 w-screen h-screen z-40"
          onClick={() => {
            setConfirmBox(false);
          }}
        ></div>
      )}

      {confirmBox && (
        <div className="absolute w-[600px] top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/3 p-10 rounded-xl bg-white z-50 text-black text-center">
          <p className="my-3 text-xl text-tertiary">
            Are you sure you want to delete!
          </p>

          <div className="w-full flex items-center justify-between mt-10 px-28">
            <button
              onClick={() => setConfirmBox(false)}
              className="px-10 py-3 rounded-lg bg-[rgba(0,0,0,0.2)]"
            >
              Cancel
            </button>

            <button
              onClick={() => {
                setConfirmBox(false);
                deleteArtwork(artId);
              }}
              className="px-10 py-3 rounded-lg bg-tertiary text-white"
            >
              Next
            </button>
          </div>
        </div>
      )}
      {/* Confirm Box */}

      {/* if token expired */}
      {tokenExpired && <SessionExpired />}

      {/* Failure Alert */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideAlert ? 'right-4' : '-right-full'
        }`}
      >
        <SideAlert name="Something went wrong" />
      </div>
      {/* Failure Alert */}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full sm:px-6 lg:px-8">
            <div className="h-[550px] 2xl:h-[700px] overflow-y-auto customScrollY pr-3">
              <table
                className="min-w-full text-left text-sm font-light rounded-md border-spacing-y-2 border-separate"
                cellSpacing={12}
              >
                <tbody>
                  {artworks
                    ?.filter((artwork) => {
                      return search.toLowerCase() === ''
                        ? artwork
                        : artwork.c1.toLowerCase().includes(search);
                    })
                    .map((artwork) => (
                      <tr
                        key={artwork._id}
                        className="py-2 bg-white hover:bg-secondaryColor cursor-pointer bg-opacity-10 backdrop-blur-lg"
                      >
                        <td className="whitespace-nowrap px-6 py-2  rounded-tl-xl rounded-bl-xl">
                          <img src={artwork.c3} alt="" className="w-12" />
                        </td>
                        <td className="whitespace-nowrap px-6 py-2">
                          {artwork.c1}
                        </td>
                        <td className="whitespace-nowrap px-6 py-2">
                          {artwork.c2}
                        </td>
                        <td className="whitespace-nowrap px-6 py-2">
                          {moment(artwork.updatedAt).calendar()}
                        </td>
                        <td className="whitespace-nowrap py-2">
                          {permission === 'full' ? (
                            <button
                              onClick={() => downloadArtwork(artwork._id)}
                              className="text-3xl text-white font-semibold"
                            >
                              <IoMdDownload />
                            </button>
                          ) : (
                            <button className="text-3xl text-white font-semibold">
                              <IoMdDownload />
                            </button>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-6 py-2 rounded-tr-xl rounded-br-xl">
                          {permission === 'full' ? (
                            <button
                              onClick={() => {
                                setConfirmBox((prev) => (prev = true));
                                setArtId((prev) => (prev = artwork._id));
                              }}
                              className="text-3xl text-secondaryRed"
                            >
                              <BsFillTrashFill />
                            </button>
                          ) : (
                            <button className="text-3xl text-secondaryRed">
                              <BsFillTrashFill />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtWorks;
