import React, { useEffect, useRef, useState } from 'react';
import FolderItem from './FolderItem';
import SessionExpired from '../../components/SessionExpired';
import { fetchServer } from '../../shares/helper';

const Projects = ({ setLoading, search }) => {
  let [projects, setProjects] = useState([]);
  const [tokenExpired, setTokenExpired] = useState(false);

  const dataFetchRef = useRef(false);

  // fetching projects data from the server
  const fetchProjects = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const data = await fetchServer('/cloud/project_collection', 'GET');

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        // console.log(data);
        setProjects((projects = data.result.data));
        // console.log(projects);
      } catch (error) {
        console.log(error);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // to trigger fetchprojects
  useEffect(() => {
    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    fetchProjects();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* if token expired */}
      {tokenExpired && <SessionExpired />}

      <div className="grid grid-cols-4 2xl:grid-cols-5 gap-3 h-[550px] 2xl:h-[700px] overflow-y-auto customScrollY pr-3">
        {projects?.map((project) => (
          <FolderItem key={project._id} />
        ))}
      </div>
    </>
  );
};

export default Projects;
