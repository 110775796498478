import React from 'react';
import { RiAlertFill } from 'react-icons/ri';

const SideAlert = (props) => {
  return (
    <div className="px-10 py-3 rounded-xl w-96 bg-white flex space-x-3 items-center border-2 border-secondaryRed text-secondaryRed">
      <div className="text-3xl">
        <RiAlertFill />
      </div>
      <p>{props.name}</p>
    </div>
  );
};

export default SideAlert;
