import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import MusicData from '../../assets/images/musicdata.png';
import { Link } from 'react-router-dom';
import Loading from '../../sparepages/Loading';
import { fetchServer } from '../../shares/helper';
import SessionExpired from '../../components/SessionExpired';
import SideAlert from '../../components/SideAlert';

const PreviewProfile = () => {
  const [userDatas, setUserDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const dataFetchRef = useRef(false);
  // const deleteSession = async (e) => {

  //   localStorage.clear();
  //   window.location.reload();
  // }
  // fetching profile data from the server
  const fetchProfile = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const data = await fetchServer('/profile/user_profile', 'GET');

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.error === '') {
          setUserDatas((prev) => (prev = data.result.data));
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prev) => (prev = true));
    }
  };

  useEffect(() => {
    if (dataFetchRef.current) return;
    dataFetchRef.current = true;
    fetchProfile();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full h-full px-5 py-10">
      <img
        src={BackgroundImageZZ}
        className="absolute top-0 left-0 w-full h-full bg-center -z-10"
        alt=""
      />

      {/* if token expired */}
      {tokenExpired && <SessionExpired />}

      {/* Failure Alert */}
      <div
        className={`fixed top-4 transition-all duration-700 ${sideAlert ? 'right-4' : '-right-full'
          }`}
      >
        <SideAlert name="Something went wrong" />
      </div>
      {/* Failure Alert */}

      {/* loading box */}
      {loading && (
        <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
          <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
            <Loading />
          </div>
        </div>
      )}
      {/* loading box */}

      <div className="w-3/4 md:w-2/5 py-14 absolute top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2 bg-white bg-opacity-10 backdrop-blur-lg rounded-xl flex flex-col space-y-4 items-center">
        <Link
          to={'/profile/identity'}
          className="absolute top-5 right-5 bg-secondaryColor text-white px-5 py-2 rounded-md"
        >
          <span>Identity</span>
        </Link>

        <div className="flex flex-col items-center space-y-6">
          <img src={MusicData} alt="" className="w-28" />

          <p className="text-xl font-semibold">
            {userDatas?.c2} {userDatas?.c3}
          </p>
        </div>

        <div className="w-3/4 space-y-8">
          <div className="flex w-full ">
            <p className="w-40 xl:w-56">Band Name</p>
            <p className="w-16">-</p>
            <p>{userDatas?.c4}</p>
          </div>

          <div className="flex w-full">
            <p className="w-40 xl:w-56">CAE/IPI</p>
            <p className="w-16">-</p>
            <p>{userDatas?.c5}</p>
          </div>

          <div className="flex w-full">
            <p className="w-40 xl:w-56">ISRC</p>
            <p className="w-16">-</p>
            <p>{userDatas?.c6}</p>
          </div>

          <div className="flex w-full">
            <p className="w-40 xl:w-56">PROs</p>
            <p className="w-16">-</p>
            <p>{userDatas?.c7}</p>
          </div>
        </div>
      </div>


      <button
        onClick={(e) => {
          localStorage.clear();
          window.location.reload();
}}
        className=" absolute bottom-10 mr-32 right-40 bg-secondaryRed text-white px-5 py-2 rounded-md active:outline-none focus:outline-none">
        Logout
      </button>


    </div>
  );
};

export default PreviewProfile;
