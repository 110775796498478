import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import { useNavigate, useParams } from 'react-router-dom';
import BackLink from '../../components/Layouts/BackLink';
import axios from 'axios';
import moment from 'moment';
import { HiDownload } from 'react-icons/hi';
import Loading from '../../sparepages/Loading';
import html2pdf from 'html2pdf.js';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';
import SessionExpired from '../../components/SessionExpired';
import SideSuccess from '../../components/SideSuccess';

const PreviewMusicData = () => {
  const params = useParams();

  const [artWorkSrc, setArtWorkSrc] = useState('');
  const [primaryArtist, setPrimaryArtist] = useState('');
  const [primaryTitle, setPrimaryTitle] = useState('');
  const [featuredArtists, setFeaturedArtists] = useState('');
  const [composers, setComposers] = useState('');
  const [producers, setProducers] = useState('');
  const [songId, setSongId] = useState('');
  const [yearOfRelease, setYearOfRelease] = useState('');
  const [duration, setDuration] = useState('');
  const [albumTitle, setAlbumTitle] = useState('');
  const [iswc, setIswc] = useState('');
  const [publisherIpi, setPublisherIpi] = useState('');
  const [publisherName, setPublisherName] = useState('');
  const [collectShare, setCollectShare] = useState('');
  const [recordingTitle, setRecordingTitle] = useState('');
  const [recordingArtist, setRecordingArtist] = useState('');
  const [recordingIsrc, setRecordingIsrc] = useState('');
  const [label, setLabel] = useState('');
  const [status, setStatus] = useState(1);
  const [projectId, setProjectId] = useState('');
  const [artId, setArtId] = useState('');

  const [loading, setLoading] = useState(true);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [permission, setPermission] = useState('view');
  const [sideAlert, setSideAlert] = useState(false);
  const [sideSuccess, setSideSuccess] = useState(false);

  let [startPrint, setStartPrint] = useState(false);

  const navigate = useNavigate();

  const dataFetchRef = useRef(false);

  // check the permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const body = {
          page: 'music_data_preview',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        // console.log(data.result);

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          fetchMusicData();
          artId !== '' && fetchArtwork(artId);
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // fetching music data from the server
  const fetchMusicData = async () => {
    try {
      const body = {
        songId: params.id,
      };

      const data = await fetchServer(
        '/project/music_data_preview',
        'POST',
        body
      );

      if (data) {
        setLoading((prevState) => (prevState = false));
      }

      setArtWorkSrc((prevState) => (prevState = data.result.data[0].c6.c3));
      setArtId((prevState) => (prevState = data.result.data[0].c6._id));
      setPrimaryArtist((prevState) => (prevState = data.result.data[0].c2));
      setPrimaryTitle((prevState) => (prevState = data.result.data[0].c8));
      setFeaturedArtists((prevState) => (prevState = data.result.data[0].c3));
      setComposers((prevState) => (prevState = data.result.data[0].c4));
      setProducers((prevState) => (prevState = data.result.data[0].c5));
      setSongId((prevState) => (prevState = data.result.data[0].c7));
      setYearOfRelease((prevState) => (prevState = data.result.data[0].c9));
      setDuration(
        (prevState) => (prevState = data.result.data[0].c10.$numberDecimal)
      );
      setAlbumTitle((prevState) => (prevState = data.result.data[0].c11));
      setIswc((prevState) => (prevState = data.result.data[0].c12));
      setPublisherIpi((prevState) => (prevState = data.result.data[0].c13));
      setPublisherName((prevState) => (prevState = data.result.data[0].c14));
      setCollectShare((prevState) => (prevState = data.result.data[0].c15));
      setRecordingTitle((prevState) => (prevState = data.result.data[0].c16));
      setRecordingArtist((prevState) => (prevState = data.result.data[0].c17));
      setRecordingIsrc((prevState) => (prevState = data.result.data[0].c18));
      setLabel((prevState) => (prevState = data.result.data[0].c19));
      setStatus((prevState) => (prevState = data.result.data[0].c21));
      setProjectId((prevState) => (prevState = data.result.data[0]._id));
    } catch (error) {
      setSideAlert((prev) => (prev = true));

      setTimeout(() => {
        setSideAlert((prev) => (prev = false));
      }, 2000);
    }
  };

  // fetching artwork from the server
  const fetchArtwork = async (artId) => {
    try {
      const token = localStorage.getItem('token');
      const body = {
        artId: artId,
      };
      const data = await axios.delete('/project/music_data_preview', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
      });
      setArtWorkSrc(
        (prev) =>
          (prev = `data:${data.data.contentType};base64,${data.data.image}`)
      );
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // request music data to publish to the admin
  const requestToAdmin = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const body = {
          songId: songId,
          projId: projectId,
          status: 0,
        };

        const data = await fetchServer('/project/music_data', 'POST', body);

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        setSideSuccess((prev) => (prev = true));

        setTimeout(() => {
          setSideSuccess((prev) => (prev = false));

          setTimeout(() => {
            navigate('/productManager');
          }, 0);
        }, 2000);
        // console.log(data);
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // download entire page as pdf file
  const downloadPdf = () => {
    setStartPrint((startPrint = true));

    setTimeout(() => {
      var element = document.getElementById('card');

      // console.log(element);
      var opt = {
        margin: 0.3,
        filename: new Date().getTime() + '.pdf',
        image: {
          type: 'jpeg',
          quality: 1,
        },
        html2canvas: {
          scale: 4,
          dpi: 72,
          letterRendering: true,
        },
        jsPDF: {
          unit: 'mm',
          format: 'A4',
          orientation: 'landscape',
        },
      };
      html2pdf().from(element).set(opt).save();
      setStartPrint((startPrint = false));
    }, 0);
  };

  // download music data as zip from the server
  const downloadZip = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const formData = new FormData();
        formData.append('musicId', songId);
        formData.append('artId', artId);

        const data = await fetchServer(
          '/project/music_data_preview',
          'PATCH',
          formData,
          true
        );

        if (data) {
          setLoading((prev) => (prev = false));
        }

        console.log(data.data);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'zippedFiles.zip');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  useEffect(() => {
    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    checkPermission();

    // eslint-disable-next-line
  }, []);

  return (
    (permission === 'view' || permission === 'full') && (
      <div className="w-full h-full px-5 py-5" id="card">
        <img
          src={BackgroundImageZZ}
          className={`top-0 left-0 w-full h-full bg-center -z-10 ${
            startPrint ? 'hidden' : 'absolute'
          }`}
          alt="background"
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        {/* Success Box */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideSuccess ? 'right-4' : '-right-full'
          }`}
        >
          <SideSuccess name={'Successfully requested to the admin!'} />
        </div>
        {/* Success Box */}

        <div className="w-3/4 md:w-4/5 py-5 m-auto bg-white bg-opacity-10 backdrop-blur-lg rounded-xl flex flex-col space-y-8 items-center">
          <div className="w-28 h-28 rounded-full overflow-hidden flex justify-center items-center">
            <img src={artWorkSrc} alt="" className="w-full object-cover" />
          </div>

          <div className="w-full flex justify-evenly">
            <div className="space-y-4 px-10">
              <div className="w-full flex justify-center items-center">
                <div
                  className={`w-10 h-10 rounded-full border flex justify-center items-center ${
                    startPrint ? 'pb-6' : ''
                  } font-bold text-xl`}
                >
                  i
                </div>
              </div>

              <div className="flex w-full">
                <p className="w-56">Primary Artist</p>
                <p className="w-16">-</p>
                <p>{primaryArtist}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Primary Titles</p>
                <p className="w-16">-</p>
                <p className="w-36">{primaryTitle}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Featured Artists</p>
                <p className="w-16">-</p>
                <p>{featuredArtists}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Composers</p>
                <p className="w-16">-</p>
                <p>{composers}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Producers</p>
                <p className="w-16">-</p>
                <p>{producers}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Year of Release</p>
                <p className="w-16">-</p>
                <p>{moment(yearOfRelease).calendar()}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Duration</p>
                <p className="w-16">-</p>
                <p>{duration}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Album Titles</p>
                <p className="w-16">-</p>
                <p className="w-36">{albumTitle}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">ISWC</p>
                <p className="w-16">-</p>
                <p>{iswc}</p>
              </div>
            </div>

            <div className="bg-white h-96 w-0.5 absolute left-1/2 -translate-x-1/2"></div>

            <div className="space-y-4 px-10">
              <div className="w-full flex justify-center items-center">
                <div
                  className={`w-10 h-10 rounded-full border flex justify-center items-center ${
                    startPrint ? 'pb-6' : ''
                  } font-bold text-xl`}
                >
                  c
                </div>
              </div>
              <div className="flex w-full ">
                <p className="w-56">Publisher IPI</p>
                <p className="w-16">-</p>
                <p>{publisherIpi}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Publisher Name</p>
                <p className="w-16">-</p>
                <p>{publisherName}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Collection Share</p>
                <p className="w-16">-</p>
                <p>{collectShare}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Recording Titles</p>
                <p className="w-16">-</p>
                <p>{recordingTitle}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Recording Artist</p>
                <p className="w-16">-</p>
                <p>{recordingArtist}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Recording ISRC</p>
                <p className="w-16">-</p>
                <p>{recordingIsrc}</p>
              </div>

              <div className="flex w-full">
                <p className="w-56">Label</p>
                <p className="w-16">-</p>
                <p>{label}</p>
              </div>
            </div>
          </div>

          {startPrint && (
            <div className="flex w-full justify-end pt-24 -pr-20">
              Power by Ex;braiN Technology
            </div>
          )}

          {(status === 0 || status === 1) && (
            <>
              {status === 1
                ? !startPrint && (
                    <button
                      onClick={() => requestToAdmin()}
                      className="absolute bottom-5 right-5 bg-primaryColor px-5 py-2 rounded-lg"
                    >
                      Request to admin
                    </button>
                  )
                : !startPrint && (
                    <button className="absolute bottom-5 right-5 bg-primaryColor px-5 py-2 rounded-lg">
                      Request pending
                    </button>
                  )}
            </>
          )}
        </div>

        {!startPrint && (
          <div className="absolute bottom-5 pr-16 flex w-full justify-between items-center">
            <BackLink
              to={`/productManager${
                status === 0 || status === 2 ? '' : '/edit/' + params.id
              }`}
            />

            {status === 2 && (
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => downloadZip()}
                  className="bg-primaryColor px-5 py-2 rounded-lg flex items-center space-x-2"
                >
                  <HiDownload />
                  <p>Download as zip</p>
                </button>

                <button
                  onClick={() => downloadPdf()}
                  className="bg-primaryColor px-5 py-2 rounded-lg flex items-center space-x-2"
                >
                  <HiDownload />
                  <p>Export as pdf</p>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default PreviewMusicData;
