import React from 'react';
import loadingGif from '../assets/images/loadinggif.gif';
import OpaqueLogo from '../assets/images/opaquelogo.png';

const Loading = () => {
  return (
    <div className="w-full h-full flex justify-center items-center flex-col">
      <img src={OpaqueLogo} alt="" />
      <img src={loadingGif} alt="" />
    </div>
  );
};

export default Loading;
