import React from 'react';

const YourMessage = ({ content }) => {
  return (
    <div className="w-full flex flex-row-reverse justify-start mt-3">
      <div className="rounded-xl bg-white py-2 px-3 text-black">
        <p>{content}</p>
      </div>
    </div>
  );
};

export default YourMessage;
