import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import { BiSearch } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Loading from '../../sparepages/Loading';
import SessionExpired from '../../components/SessionExpired';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';

const ProductManager = () => {
  let [musicData, setMusicData] = useState([]);
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState('view');
  const [tokenExpired, setTokenExpired] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const navigate = useNavigate();

  const dataFetchRef = useRef(false);

  // check the permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const body = {
          page: 'project_management',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        // console.log(data.result);

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          fetchMusicData();
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // chect permission to access to the next page
  const checkAccess = async (musicId, preview) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: preview === true ? 'music_data_preview' : 'music_data',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        // console.log(data.result);
        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          if (preview === true) {
            navigate(`edit/${musicId}/preview`);
          } else {
            navigate(`edit/${musicId}`);
          }
        } else {
          setSideAlert((prev) => (prev = true));

          setTimeout(() => {
            setSideAlert((prev) => (prev = false));
          }, 2000);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // fetching music data from the server
  const fetchMusicData = async () => {
    try {
      const data = await fetchServer('/project/project_management', 'GET');

      console.log(data);

      if (data) {
        setLoading((prevState) => (prevState = false));
      }

      setMusicData((musicData = data.result.data));
      // console.log(data);
    } catch (error) {
      setSideAlert((prev) => (prev = true));

      setTimeout(() => {
        setSideAlert((prev) => (prev = false));
      }, 2000);
    }
  };

  useEffect(() => {
    document.title = 'Product Manager - Overstood';

    if (dataFetchRef.current) return;

    dataFetchRef.current = true;

    checkPermission();

    // eslint-disable-next-line
  }, []);

  return (
    (permission === 'view' || permission === 'full') && (
      <div className="w-full h-full space-y-5 px-5 py-1">
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        <div className="w-full flex justify-end items-center">
          <div className="flex items-center justify-between space-x-3 bg-white px-5 py-3 rounded-full text-black w-96">
            <input
              type="text"
              placeholder="Search Here"
              className="focus:outline-none w-full"
              onChange={(e) => setSearch(e.target.value)}
            />
            <BiSearch className="text-xl" />
          </div>
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full sm:px-6 lg:px-8">
              <div className="h-[550px] 2xl:h-[700px] overflow-y-auto customScrollY pr-3">
                <table
                  className="min-w-full text-left text-sm font-light rounded-md border-spacing-y-2 border-separate"
                  cellSpacing={12}
                >
                  <tbody>
                    {musicData
                      ?.filter((music) => {
                        return search.toLowerCase() === ''
                          ? music
                          : music.c1.toLowerCase().includes(search);
                      })
                      .map((music) => (
                        <tr
                          key={music._id}
                          className="py-2 bg-white hover:bg-secondaryColor cursor-pointer bg-opacity-20 backdrop-blur-lg"
                        >
                          <td className="whitespace-nowrap px-6 py-2  rounded-tl-xl rounded-bl-xl">
                            <p className="font-bold">{music.c1}</p>
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            {music.c5 === 0
                              ? 'pending'
                              : music.c5 === 1
                              ? 'unfinish'
                              : music.c5 === 2
                              ? 'finish'
                              : 'no data'}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2">
                            {moment(music.updatedAt).calendar()}
                          </td>
                          <td className="whitespace-nowrap py-2 rounded-tr-xl rounded-br-xl">
                            {music.c5 === 1 || music.c5 === 3 ? (
                              <button
                                onClick={() => checkAccess(music._id, false)}
                                className="text-sm font-semibold bg-white rounded-full px-3 py-2 text-black"
                              >
                                Add Music Data
                              </button>
                            ) : (
                              <button
                                onClick={() => checkAccess(music._id, true)}
                                className="text-sm font-semibold bg-white rounded-full px-3 py-2 text-black"
                              >
                                Music Data Preview
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ProductManager;
