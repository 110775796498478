import React, { useEffect, useRef, useState } from 'react';
import gradientBackground from '../../assets/styles/GradientBackground.module.css';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import { useNavigate } from 'react-router-dom';
import SessionExpired from '../../components/SessionExpired';
import SideAlert from '../../components/SideAlert';
import Loading from '../../sparepages/Loading';
import { fetchServer } from '../../shares/helper';

const HomePage = () => {
  const [permission, setPermission] = useState('view');
  const [tokenExpired, setTokenExpired] = useState(false);

  const [sideAlert, setSideAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const dataFetchRef = useRef(false);

  const navigate = useNavigate();

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'dashboard',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // chect permission to access to the next page
  const checkAccess = async (pagename) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: pagename,
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        // console.log(data.result);
        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          if (pagename.includes('music')) {
            navigate('/uploadingMusic');
          } else if (pagename.includes('art')) {
            navigate('/artworks');
          } else {
            navigate('/mastering');
          }
        } else {
          setSideAlert((prev) => (prev = true));

          setTimeout(() => {
            setSideAlert((prev) => (prev = false));
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // to trigger permission check
  useEffect(() => {
    document.title = 'Dashboard - Overstood';

    // to prevent running twice in fetch data
    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    checkPermission();

    // eslint-disable-next-line
  }, []);

  return (
    (permission === 'view' || permission === 'full') && (
      <div className="w-full h-full p-5 px-20 pb-24">
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* Side Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="You have no access!" />
        </div>
        {/* Side Alert */}

        {/* loading */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading */}

        <div className="w-full h-1/2 mb-10 bg-white rounded-lg bg-opacity-10 backdrop-blur-lg flex justify-center items-center">
          <p className="text-5xl">Welcome To Your Client Dashboard</p>
        </div>

        <div className="grid grid-cols-3 gap-10 h-1/2">
          {/* to the uploading music page */}
          <button
            onClick={() => checkAccess('upload_music')}
            className={`flex justify-center h-3/4 items-center rounded-md relative overflow-hidden ${gradientBackground.gradientBackgroundOne}`}
          >
            <div>
              <p className="text-center text-4xl font-bold">
                Upload <br /> Your <br /> Song Recordings
              </p>
            </div>
          </button>
          {/* to the uploading music page */}

          <button
            onClick={() => checkAccess('art_work')}
            className={`flex justify-center h-3/4 items-center rounded-md relative overflow-hidden ${gradientBackground.gradientBackgroundOne}`}
          >
            <div>
              <p className="text-center text-4xl font-bold">
                Upload <br /> Your <br /> Artworks
              </p>
            </div>
          </button>

          <button
            onClick={() => checkAccess('mastering')}
            className={`flex justify-center h-3/4 items-center rounded-md relative overflow-hidden ${gradientBackground.gradientBackgroundOne}`}
          >
            <div>
              <p className="text-center text-4xl font-bold">
                Mastering <br /> Your <br /> Recordings
              </p>
            </div>
          </button>
        </div>
      </div>
    )
  );
};

export default HomePage;
