import React, { useEffect, useRef, useState } from 'react';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import Landr from '../../assets/images/landr.png';
import BackLink from '../../components/Layouts/BackLink';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BiSearch } from 'react-icons/bi';
import Loading from '../../sparepages/Loading';
import SessionExpired from '../../components/SessionExpired';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';

const Mastering = () => {
  let [songRecordings, setSongRecordings] = useState([]);
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(true);
  const [sideAlert, setSideAlert] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);

  const [permission, setPermission] = useState('view');

  const navigate = useNavigate();

  const dataFetchRef = useRef(false);

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'mastering_list',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        if (data) {
          fetchSongRecordings();
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // chect permission to access to the next page
  const checkAccess = async (songRecordingId) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'mastering',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        // console.log(data.result);
        if (data) {
          setLoading((prev) => (prev = false));
        }

        console.log(data);

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          navigate(`edit/${songRecordingId}`);
        } else {
          setSideAlert((prev) => (prev = true));

          setTimeout(() => {
            setSideAlert((prev) => (prev = false));
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // get song recordings data from the server
  const fetchSongRecordings = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const data = await fetchServer(
          '/cloud/songrecording_collection',
          'GET'
        );

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        // console.log(data.result.data);
        setSongRecordings((songRecordings = data.result.data));
        // console.log(songRecordings);
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // to trigger check permission and fetching song recordings
  useEffect(() => {
    document.title = 'Mastering - Overstood';

    // to prevent running twice in fetch data
    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    checkPermission();

    // eslint-disable-next-line
  }, []);

  return (
    (permission === 'view' || permission === 'full') && (
      <div className="w-full h-full py-3 px-5">
        {/* background image */}
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        <div className="w-full flex justify-end items-center my-4">
          <div className="flex items-center justify-between space-x-3 bg-white px-5 py-3 rounded-full text-black w-96">
            <input
              type="text"
              placeholder="Search Here"
              className="focus:outline-none w-full"
              onChange={(e) => setSearch(e.target.value)}
            />
            <BiSearch className="text-xl" />
          </div>
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full sm:px-6 lg:px-8">
              <div className="h-[600px] 2xl:h-[750px] overflow-y-auto customScrollY px-3">
                <table
                  className="min-w-full text-left text-sm font-light rounded-md border-spacing-y-2 border-separate"
                  cellSpacing={12}
                >
                  <tbody>
                    {songRecordings
                      .filter((songRecording) => {
                        return search.toLowerCase() === ''
                          ? songRecording
                          : songRecording.c1.toLowerCase().includes(search);
                      })
                      .map((songRecording) => (
                        <tr className="py-2" key={songRecording._id}>
                          <td className="whitespace-nowrap px-6 py-2 bg-white bg-opacity-20 backdrop-blur-lg rounded-tl-xl rounded-bl-xl">
                            <p className="font-bold">{songRecording.c1}</p>
                          </td>
                          <td className="whitespace-nowrap px-6 py-2 bg-white bg-opacity-20 backdrop-blur-lg">
                            {songRecording.c6.$numberDecimal} mins
                          </td>
                          <td className="whitespace-nowrap px-6 py-2 bg-white bg-opacity-20 backdrop-blur-lg">
                            {moment(songRecording.updatedAt).calendar()}
                          </td>
                          <td className="whitespace-nowrap px-6 py-2 bg-white bg-opacity-20 backdrop-blur-lg">
                            mp3
                          </td>
                          <td className="whitespace-nowrap py-2 bg-white bg-opacity-20 backdrop-blur-lg rounded-tr-xl rounded-br-xl">
                            {!songRecording.c4 ? (
                              permission === 'full' ? (
                                <button
                                  onClick={() => checkAccess(songRecording._id)}
                                  className="text-sm font-semibold bg-primaryColor rounded-md px-3 py-2 text-black"
                                >
                                  Mastering
                                </button>
                              ) : (
                                <button className="text-sm font-semibold bg-primaryColor rounded-md px-3 py-2 text-black">
                                  Mastering
                                </button>
                              )
                            ) : (
                              <button className="text-sm font-semibold rounded-md px-3 py-2 text-black">
                                <img src={Landr} alt="" className="w-10" />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-5 pr-10 flex w-full justify-between items-center">
          <BackLink to={'/'} />
        </div>
      </div>
    )
  );
};

export default Mastering;
