import React, { useState } from "react";
import LoginBg from "../../assets/images/loginbg.png";
// import LoginGoogle from '../../assets/images/logingoogle.png';
// import LoginApple from '../../assets/images/loginapple.png';
import { useNavigate, Link } from "react-router-dom";
import SideAlert from "../../components/SideAlert";
import Loading from "../../sparepages/Loading";
import SideSuccess from "../../components/SideSuccess";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [sideAlert, setSideAlert] = useState(false);
  const [sideSuccess, setSideSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("Something went wrong!!!");

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading((prev) => (prev = true));

    try {
      const body = {
        c2: email,
        c3: password
      };

      const axiosInstance = axios.create({
        baseURL: "https://api.auspiciousmusic.com" // Your backend URL here
      });
      const { data } = await axiosInstance.post("/auth/login", body);
      if (data) {
        setLoading((prev) => (prev = false));
      }
      if (data.result.error) {
        setErrorText(data.result.error);
        setSideAlert(true);
        setTimeout(() => {
          setSideAlert(false);
        }, 2000);

        return;
      }

      if (data.result.data.user.c6 === false) {
        localStorage.setItem("token", data.result.data.user.token);
        navigate("otp");
      } else {
        if (
          data.result.data.user.cat === 0 ||
          data.result.data.user.cat === 1
        ) {
          localStorage.setItem("name", data.result.data.user.c1);
          localStorage.setItem("token", data.result.data.user.token);
          localStorage.setItem("userId", data.result.data.user._id);

          setSideSuccess(true);

          setTimeout(() => {
            setSideSuccess(false);

            navigate("/music");
          }, 1000);
        } else if (data.result.data.user.cat === 2) {
          setSideSuccess(true);

          setTimeout(() => {
            setSideSuccess(false);

            navigate("/login");
          }, 1000);
        } else {
          setSideSuccess(true);

          setTimeout(() => {
            setSideSuccess(false);
            localStorage.setItem("id", data.result.data.user._id);
            navigate("/login/joinArtists");
          }, 1000);
        }
      }
    } catch (error) {
      // Handle error response
      // console.error('Authentication failed:', error.response.data.error);
    }
  };

  return (
    <div className="w-screen h-screen relative bg-black">
      <div className="w-full h-1/2">
        <img
          src={LoginBg}
          alt="Login Background"
          className="object-center object-cover w-full h-full"
        />
      </div>

      {/* Error Box */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideAlert ? "right-4" : "-right-full"
        }`}
      >
        <SideAlert name={errorText} />
      </div>
      {/* Error Box */}

      {/* Success Box */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideSuccess ? "right-4" : "-right-full"
        }`}
      >
        <SideSuccess name={"Login Successful!"} />
      </div>
      {/* Success Box */}

      {/* loading box */}
      {(sideSuccess || loading) && (
        <div className="w-screen h-screen absolute -top-24 left-0 z-40"></div>
      )}

      {/* loading box */}
      {loading && (
        <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
          <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
            <Loading />
          </div>
        </div>
      )}
      {/* loading box */}

      <div className="w-3/4 md:w-2/5 py-16 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-20 backdrop-blur-lg rounded-xl flex flex-col space-y-8 items-center">
        <p className="text-xl font-semibold mb-3 text-white">Login</p>
        <form
          className="w-full flex flex-col space-y-8 items-center"
          onSubmit={handleFormSubmit}
        >
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-3/4 md:w-3/5 py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-3/4 md:w-3/5 py-2 px-3 text-white bg-white bg-opacity-10 rounded-xl active:outline-none focus:outline-none"
          />

          <button className="w-3/4 md:w-3/5 py-2 px-3 text-black text-sm bg-white rounded-xl active:outline-none focus:outline-none">
            Next
          </button>
        </form>

        {/* <p className="text-2xl text-white">or</p>

        <div className="flex items-center space-x-3">
          <button>
            <img src={LoginGoogle} alt="Google button" className="w-8" />
          </button>
          <button>
            <img src={LoginApple} alt="Apple button" className="w-8" />
          </button>
        </div> */}

        <div className="flex items-center">
          <Link
            to={"/register"}
            className="text-secondaryBlue text-xs underline underline-offset-2"
          >
            Don't have an account? Register Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
