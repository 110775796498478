import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackLink from '../../components/Layouts/BackLink';
import { BiSearch } from 'react-icons/bi';
import BackgroundImageZZ from '../../assets/images/backgroundImageZZ.png';
import { BsFillImageFill } from 'react-icons/bs';
import Loading from '../../sparepages/Loading';
import SessionExpired from '../../components/SessionExpired';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';

const Artworks = () => {
  let [artworks, setArtworks] = useState([]);
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(true);
  const [sideAlert, setSideAlert] = useState(false);

  const [permission, setPermission] = useState('view');
  const [tokenExpired, setTokenExpired] = useState(false);

  const navigate = useNavigate();

  const dataFetchRef = useRef(false);

  // check permission to access this page
  const checkPermission = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: 'art_work',
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        if (data) {
          fetchArtworks();
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0) {
          setPermission((prevState) => (prevState = 'view'));
        } else if (data.result.msg === 1) {
          setPermission((prevState) => (prevState = 'full'));
        } else {
          navigate('/music');
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // chect permission to access to the next page
  const checkAccess = async (pagename) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prev) => (prev = true));
      try {
        const body = {
          page: pagename,
          method: 'GET',
        };

        const data = await fetchServer('/permitCheck', 'POST', body);

        // console.log(data.result);
        if (data) {
          setLoading((prev) => (prev = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        if (data.result.msg === 0 || data.result.msg === 1) {
          navigate('designArtwork');
        } else {
          setSideAlert((prev) => (prev = true));

          setTimeout(() => {
            setSideAlert((prev) => (prev = false));
          }, 2000);
        }
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // fetching artworks from the server
  const fetchArtworks = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        const data = await fetchServer('/cloud/artwork_collection', 'GET');

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        setArtworks((artworks = data.result.data));
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // trigger to check permission and fetching artworks
  useEffect(() => {
    document.title = 'Artworks - Overstood';

    if (dataFetchRef.current) return;

    dataFetchRef.current = true;
    checkPermission();

    // eslint-disable-next-line
  }, []);

  return (
    (permission === 'view' || permission === 'full') && (
      <div className="w-full h-full p-5">
        {/* background image */}
        <img
          src={BackgroundImageZZ}
          className="absolute top-0 left-0 w-full h-full bg-center -z-10"
          alt=""
        />

        {/* if token expired */}
        {tokenExpired && <SessionExpired />}

        {/* loading box */}
        {loading && (
          <div className="w-screen h-screen absolute top-0 left-0 z-20 flex justify-center items-center">
            <div className="px-72 pt-24 bg-black backdrop-blur-lg bg-opacity-20 rounded-lg">
              <Loading />
            </div>
          </div>
        )}
        {/* loading box */}

        {/* Failure Alert */}
        <div
          className={`fixed top-4 transition-all duration-700 ${
            sideAlert ? 'right-4' : '-right-full'
          }`}
        >
          <SideAlert name="Something went wrong" />
        </div>
        {/* Failure Alert */}

        {/* Search artworks name */}
        <div className="w-full flex justify-end items-center">
          <div className="flex items-center justify-between space-x-3 bg-white px-5 py-3 rounded-full text-black w-96">
            <input
              type="text"
              placeholder="Search Here"
              className="focus:outline-none"
              onChange={(e) => setSearch(e.target.value)}
            />
            <BiSearch className="text-xl" />
          </div>
        </div>

        {/* artworklist */}
        {artworks.length === 0 ? (
          // if no artworks
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <p className="text-5xl">Upload new artworks.</p>
          </div>
        ) : (
          // artworks list items
          <div className="mt-8 grid grid-cols-4 2xl:grid-cols-6 gap-5 place-items-center">
            {artworks
              ?.filter((artwork) => {
                return search.toLowerCase() === ''
                  ? artwork
                  : artwork.c1.toLowerCase().includes(search);
              })
              .map((artwork) => (
                <div
                  key={artwork._id}
                  className="w-60 flex flex-col items-center space-y-3"
                >
                  <div className="w-48">
                    <img src={artwork.c3} alt="artwork" />
                  </div>
                  <p>{artwork.c1}</p>
                </div>
              ))}
          </div>
        )}

        <div className="absolute bottom-5 pr-10 flex w-full justify-between items-center">
          <BackLink to={'/'} />

          {permission === 'full' && (
            <button
              onClick={() => checkAccess('upload_artwork')}
              className="bg-secondaryColor text-white px-5 py-2 rounded-md flex space-x-3 items-center"
            >
              <BsFillImageFill />
              <span>Upload</span>
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default Artworks;
