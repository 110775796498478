import axios from "axios";
// import CryptoJS from 'crypto-js';
// const secretKey = '$3CR3CTK3Y';

// const encryptData = (data) => {
//   return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
// };

// const decryptData = (data) => {
//   const bytes = CryptoJS.AES.decrypt(data, secretKey).toString(
//     CryptoJS.enc.Utf8
//   );

//   return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// };

export const fetchServer = async (url, method, body, downloadType) => {
  const token = localStorage.getItem("token");
  const endpoint = "https://api.auspiciousmusic.com" + url;
  try {
    if (method === "GET") {
      const { data } = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // data.result = decryptData(data.result);
      return data;
    } else if (method === "POST") {
      if (downloadType === true) {
        const { data } = await axios.post(
          endpoint,
          // { data: encryptData(body) },
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        // data.result = decryptData(data.result);
        return data;
      }
      const { data } = await axios.post(
        endpoint,
        // { data: encryptData(body) },
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // data.result = decryptData(data.result);
      return data;
    } else if (method === "PUT") {
      // do something
    } else if (method === "DELETE") {
      const { data } = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // data: { data: encryptData(body) },
        body,
      });

      // data.result = decryptData(data.result);
      return data;
    } else if (method === "PATCH") {
      if (downloadType === true) {
        const { data } = await axios.patch(
          endpoint,
          // body !== undefined && { data: encryptData(body) },
          body !== undefined && body,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        // data.result = decryptData(data.result);
        return data;
      }
      const { data } = await axios.patch(
        endpoint,
        // { data: encryptData(body) },
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // data.result = decryptData(data.result);
      return data;
    }
  } catch (error) {
    return error;
  }
};
