import React, { useEffect, useRef, useState } from 'react';
import { IoMdDownload } from 'react-icons/io';
import { BsFillTrashFill } from 'react-icons/bs';
import moment from 'moment';
import { fetchServer } from '../../shares/helper';
import SideAlert from '../../components/SideAlert';
import SessionExpired from '../../components/SessionExpired';

const SongRecordings = ({ setLoading, search, setSideSuccess, permission }) => {
  let [songRecordings, setSongRecordings] = useState([]);

  const [confirmBox, setConfirmBox] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [sideAlert, setSideAlert] = useState(false);

  const [musicId, setMusicId] = useState(null);

  const dataFetchRef = useRef(false);

  // fetching song recordings from the server
  const fetchSongRecordings = async () => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      try {
        setLoading((prev) => (prev = true));
        const data = await fetchServer(
          '/cloud/songrecording_collection',
          'GET'
        );

        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        if (data.result.error?.includes('Token Expire')) {
          setTokenExpired((prevState) => (prevState = true));
        }

        setSongRecordings((songRecordings = data.result.data));
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // download song recording
  const downloadMusic = async (musicId) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const body = {
          musicId: musicId,
        };

        const data = await fetchServer(
          '/cloud/songrecording_collection',
          'POST',
          body,
          true
        );

        // console.log(data.data);
        if (data) {
          setLoading((prevState) => (prevState = false));
        }

        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'zippedFiles.zip');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  // delete song recording
  const deleteMusic = async (musicId) => {
    if (localStorage.getItem('token') && localStorage.getItem('token') !== '') {
      setLoading((prevState) => (prevState = true));
      try {
        const body = {
          musicId: musicId,
        };

        const data = await fetchServer(
          '/cloud/songrecording_collection',
          'DELETE',
          body
        );

        if (data) {
          setLoading((prevState) => (prevState = false));

          fetchSongRecordings();

          setSideSuccess((prev) => (prev = true));

          setTimeout(() => {
            setSideSuccess((prev) => (prev = false));
          }, 2000);
        }

        // console.log(data);
      } catch (error) {
        setSideAlert((prev) => (prev = true));

        setTimeout(() => {
          setSideAlert((prev) => (prev = false));
        }, 2000);
      }
    } else {
      setTokenExpired((prevState) => (prevState = true));
    }
  };

  useEffect(() => {
    if (dataFetchRef.current) return;

    dataFetchRef.current = true;

    fetchSongRecordings();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Confirm Box */}
      {confirmBox && (
        <div
          className="absolute top-0 left-0 w-screen h-screen z-40"
          onClick={() => {
            setConfirmBox(false);
          }}
        ></div>
      )}

      {confirmBox && (
        <div className="absolute w-[600px] top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/3 p-10 rounded-xl bg-white z-50 text-black text-center">
          <p className="my-3 text-xl text-tertiary">
            Are you sure you want to delete!
          </p>

          <div className="w-full flex items-center justify-between mt-10 px-28">
            <button
              onClick={() => setConfirmBox(false)}
              className="px-10 py-3 rounded-lg bg-[rgba(0,0,0,0.2)]"
            >
              Cancel
            </button>

            <button
              onClick={() => {
                setConfirmBox(false);
                deleteMusic(musicId);
              }}
              className="px-10 py-3 rounded-lg bg-tertiary text-white"
            >
              Next
            </button>
          </div>
        </div>
      )}
      {/* Confirm Box */}

      {/* if token expired */}
      {tokenExpired && <SessionExpired />}

      {/* Failure Alert */}
      <div
        className={`fixed top-4 transition-all duration-700 ${
          sideAlert ? 'right-4' : '-right-full'
        }`}
      >
        <SideAlert name="Something went wrong" />
      </div>
      {/* Failure Alert */}

      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full sm:px-6 lg:px-8">
            <div className="h-[550px] 2xl:h-[700px] overflow-y-auto customScrollY pr-3">
              <table
                className="min-w-full text-left text-sm font-light rounded-md border-spacing-y-2 border-separate"
                cellSpacing={12}
              >
                <tbody>
                  {songRecordings
                    ?.filter((songRecording) => {
                      return search.toLowerCase() === ''
                        ? songRecording
                        : songRecording.c1.toLowerCase().includes(search);
                    })
                    .map((songRecording) => (
                      <tr
                        key={songRecording._id}
                        className="py-2 bg-white hover:bg-secondaryColor cursor-pointer bg-opacity-10 backdrop-blur-lg"
                      >
                        <td className="whitespace-nowrap px-6 py-2  rounded-tl-xl rounded-bl-xl">
                          <p className="font-bold">{songRecording.c1}</p>
                        </td>
                        <td className="whitespace-nowrap px-6 py-2">
                          {songRecording.c6.$numberDecimal} mins
                        </td>
                        <td className="whitespace-nowrap px-6 py-2">
                          {songRecording.c5 === 0
                            ? 'pending'
                            : songRecording.c5 === 1
                            ? 'unfinish'
                            : songRecording.c5 === 2
                            ? 'finish'
                            : 'no data'}
                        </td>
                        <td className="whitespace-nowrap px-6 py-2">
                          {moment(songRecording.updatedAt).calendar()}
                        </td>
                        <td className="whitespace-nowrap px-6 py-2">Mp3</td>
                        <td className="whitespace-nowrap py-2">
                          <button className="text-sm font-semibold bg-primaryColor rounded-md px-3 py-2 text-black">
                            Mastering
                          </button>
                        </td>
                        <td className="whitespace-nowrap py-2">
                          {permission === 'full' ? (
                            <button
                              onClick={() => downloadMusic(songRecording._id)}
                              className="text-3xl text-white font-semibold"
                            >
                              <IoMdDownload />
                            </button>
                          ) : (
                            <button className="text-3xl text-white font-semibold">
                              <IoMdDownload />
                            </button>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-6 py-2 rounded-tr-xl rounded-br-xl">
                          {permission === 'full' ? (
                            <button
                              onClick={() => {
                                setConfirmBox((prev) => (prev = true));
                                setMusicId(
                                  (prev) => (prev = songRecording._id)
                                );
                              }}
                              className="text-3xl text-secondaryRed"
                            >
                              <BsFillTrashFill />
                            </button>
                          ) : (
                            <button className="text-3xl text-secondaryRed">
                              <BsFillTrashFill />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SongRecordings;
